.fileListItem-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  height: 20px;
  margin: 5px;
  .fileListItem-delete {
    fill: red;
    color: red;
    &:hover {
      cursor: pointer;
    }
  }
}
