.invoices-details-container {
  padding: 1rem 4rem 0rem 7.3rem;
  .invoices-details-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-left: 0.4rem;
  }
  .secondary-details {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    span {
      cursor: pointer;
    }
    .InvoicesDiv {
      margin-left: 0.4rem;
    }
  }
  .attachment-tabs {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    .attachement-tab-controls {
      display: flex;
      height: 2.65rem;
    }
  }
  .add-attachment-item {
    cursor: pointer;
    font-weight: bold;
  }
}

.fixedpriceItem-modal {
  .fixedpriceItem-modal-content {
    display: flex;
    justify-content: space-between;
  }
  .fixedpriceItem-modal-buttons {
    display: flex;
    justify-content: end;
    padding: 2rem;
  }
}
.btnCloseActionHistoryPopupBol {
  float: right;
  margin-top: 1rem;
}
.attachmentsbuttons {
  cursor: pointer !important;
  border: none !important;
}
.attachmentsbuttons:disabled {
  cursor: not-allowed !important;
}
.binCancelManualEntryPop {
  float: right;
  position: relative;
  right: 20rem;
  bottom: 4.3rem;
  padding: 10px 30px;
}
.fixedpriceItem-modal-content .e-grid .e-gridheader tr th {
  padding-top: 11px;
}

.InvoicesDiv .e-grid .e-rowcell:last-child,
.e-grid .e-summarycell:last-child {
  padding-right: 21px !important;
}

.attachment-pallet-grid .e-grid .e-rowcell:last-child,
.e-grid .e-summarycell:last-child {
  padding-right: 21px !important;
}

.fixedpriceItem-modal .e-grid .e-rowcell:last-child,
.e-grid .e-summarycell:last-child {
  padding-right: 21px !important;
}
.History-grid {
  margin-top: 4rem;
}
.red-font {
  color: red;
}
.backToInvSpan{
  font-weight: 500;
  font-size: 1.4rem;
}
.ErrorMsg {
  color: red;
  font-size: 15px;
}
.errorTextDiv{
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  height: 8rem;
  overflow-y: scroll;
}
.txtError {
  color: red;
  font-size: 16px;
  text-decoration: inherit;
}
.btnNoConfirmPopup{
  height: 4.6rem;
  margin-left: 2rem;
}

.modal_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .inner_modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 300px;
    height: 200px;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    .modal_body {
      display: flex;
      justify-content: center;
      align-items: center;
      height:65%;
      padding: 10px;
    }
    .modal_foot {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        padding: 10px;
        margin: 0 5px;
      }
    }
  }
  .btn_close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
  }
}