.mobilefieldmainpreview {
  padding: 2px !important;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  position: relative;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
}

.countryFlagImagepreview {
  width: 25px;
  height: 15px;
  object-fit: cover;
  margin-right: 3px;
}

.Countrydropdownpreview {
  margin-left: 0;
  width: 16px;
  border: none;
}
