@import "../../../styles/global.scss";

.image-wrapper {
  display: flex;
  cursor: pointer;
  .image-content {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .image-wrapper-header {
    @include touchButton;
  }
  .image-wrapper-body {
    @include bodyStandard;
  }
  .image-bar {
    width: 8px;
  }
  p.txtOrgstructure {
    //styleName: Web/Header/H6;
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.0015em;
    text-align: center;
    // color: #f2ca52;
    color: #8c4d3f;
    padding-bottom: 15px;
  }
  p.txtPartners {
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.0015em;
    text-align: center;
    color: #8c4d3f;
    padding-bottom: 15px;
    padding-top: 8px;
    margin: 9px;
  }
  p.txtFinance {
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.0015em;
    text-align: center;
    // color: #79c24f;
    color: #458921;
    padding-bottom: 15px;
    padding-top: 25px;
    margin: 9px;
  }
  p.txtIt {
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.0015em;
    text-align: center;
    //color: #737ae6;
    color: #646ce7;
    padding-bottom: 15px;
    margin: 1px 0px 7px 0px;
  }
  p.txtInventory {
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.0015em;
    text-align: center;
    color: #636799;
    padding-bottom: 15px;
    margin: 19px 0px 9px 0px;
  }
  p.txtBOLHead {
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: bold;
    line-height: 25px;
    letter-spacing: 0.0015em;
    text-align: center;
    color: #8c4d3f;
  }
  p.txtARInvoiceHead {
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: bold;
    line-height: 25px;
    letter-spacing: 0.0015em;
    text-align: center;
    color: #f2ca52;
  }
}
