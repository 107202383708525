/* SideBarWithMenu.scss - 336 */

.sideBar {
  width: 4.7%;
  background: linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 0.9),
                  rgba(255, 255, 255, 0.9)
  ),
  #636799 !important;
  height: calc(100vh - 0px);
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0px 3px !important;
}

.mainBar {
  padding: 0;
  width: 95.3%;
  height: calc(100vh - 0px) !important;
  overflow: auto !important;
}

/* SideBarWithMenu.scss - 366 */
.sidemenu-icons {
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.menu-item {
  padding: 5px !important;
  padding-top: 10% !important;
  display: flex !important;
  justify-content: center !important;
  margin: 4px;
  border-radius: 4px;
}

.menu-name {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  justify-content: center;

  color: #1c1c1c;
  margin: 0px !important;
  padding-top: 7px !important;
}

.menu-name:hover {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  justify-content: center;

  color: #1c1c1c;
  margin: 0px !important;
  padding-top: 7px !important;
}

.lladmin-active {
  background: linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 0.6),
                  rgba(255, 255, 255, 0.6)
  ),
  #f2ca52 !important;
  border-radius: 4px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #917931 !important;
  font-weight: bold !important;
}

.lladmin-active:hover {
  background: linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 0.6),
                  rgba(255, 255, 255, 0.6)
  ),
  #f2ca52 !important;
  border-radius: 4px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #917931 !important;
  font-weight: bold !important;
}

.lladmin-name-active:hover {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  justify-content: center;

  color: #1c1c1c;
  margin: 0px !important;
  padding-top: 7px !important;
}

.touch-mode {
  margin-top: auto;
  margin-bottom: 12px;
}

.Limn_labs_logo {
  padding: 5px !important;
  padding-top: 10% !important;
  display: flex !important;
  justify-content: center !important;
  margin: 4px;
  border-radius: 4px;
}

.touch-border {
  padding-bottom: 25% !important;
  border-bottom: 1px solid #cccccc !important;
}

#header ul {
  background: linear-gradient(
                  269.69deg,
                  #636799 -0.65%,
                  #9ea2da 118.65%
  ) !important;
  color: #fff !important;
  overflow: hidden;
}

#header .right-header {
  padding: 10px 0px 0px 10px;
}

#header .left-header {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
  // padding-left: 28% !important;
}

.menubar-options {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 5px;
  margin-left: auto;
}

.current-day-time-text {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  #636799;
  border-radius: 4px;
  padding: 10px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;

  color: #ffffff;
}

#header .header-list {
  // padding-left: 15px;
  margin: 0;
  display: flex;
  // flex-direction: row-reverse;
  // padding-right: 5%;
  padding: 0 5%;
}

// .e-btn, .e-css.e-btn {
//     background-color: transparent !important;
// }

#header .list {
  list-style: none;
  cursor: pointer;
}

.profile-page {
  display: flex;
  align-content: center;
}

.location-name {
  position: static;
  width: 270px;
  height: 16px;
  left: 0px;
  top: calc(50% - 16px / 2);

  /* TableHeader */

  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;

  /* Greyscale/White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 2px;
}

/* 12 10 21 */

.submenu-show {
  padding: 0px;
  background: #fff !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  position: absolute !important;
  color: black !important;
  list-style: none;
  display: flex;
  flex-direction: column;
  z-index: 9999 !important;
  width: 292px;
  right: 5%;
}

.help-menu {
  right: 8.5% !important;
}

.submenu-list {
  background-color: #fff !important;
  padding: 15px 10px !important;
  color: #000;
}

.list-border {
  border-bottom: 1px solid #e8e8e8;
}

.submenu-option {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.035em;

  /* Greyscale/100 */

  color: #1c1c1c;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.submenu-option-sm {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.004em;

  /* Greyscale/100 */

  color: #1c1c1c;
}

.submenu-option-icon {
  margin-right: 15px;
}

// .submenu-option p{
//     white-space: nowrap;
//     width: 62%;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
.user-info {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;

  /* Greyscale/100 */

  color: #1c1c1c;
  margin-bottom: 0px;
  margin-left: 15px;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.user-caption {
  /* Body/Caption */

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.004em;

  /* Greyscale/200 */

  color: #585757;
  float: left;
}

.profile-avatar {
  border-radius: 50%;
  align-content: center;
  align-items: center;
  background-color: #bcbcbc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1em;
  font-weight: 400;
  height: 3em;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 3em;
  margin-top: 6%;
}

.list-border {
  border-bottom: 1px solid #e8e8e8;
}

.copyright-option {
  padding: 10px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.004em;

  /* Greyscale/100 */
  background: #e8e8e8;
  color: #1c1c1c;
}

.copyright-submenu {
  background-color: #e8e8e8 !important;
  padding: 10px !important;
  color: #000;
}

.submenu-option-left {
  padding-left: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.035em;

  /* Greyscale/100 */

  color: #1c1c1c;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.profile-image {
  margin-top: 5%;
  background-color: transparent !important;
}

.page-title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
}

// .masterdata-home {
//     padding: 3% 5%;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
// }
.label-title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #1c1c1c;
  letter-spacing: 0.035em;
}

.notes-preview {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;

  /* Greyscale/100 */

  color: #1c1c1c;
}

.parent-tab-width {
  padding: 1% 5% !important;
}

/* stepper control styles by manikanta */

.list--steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.list--steps li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 140px;
  flex: 0 0 175px;
}

.list--steps li.active .steps--number {
  background-color: #ffffff !important;
  border-color: #a283ed;
  color: #a283ed;
  -webkit-animation: steps 1s 1 linear forwards;
  animation: steps 1s 1 linear forwards;
  -webkit-animation-delay: 0.33s;
  animation-delay: 0.33s;
}

.list--steps li:after {
  content: "";
  display: block;
  height: 1px;
  background-color: #e0e0e0;
  left: 50%;
  right: 0;
  width: 50%;
  position: absolute;
  top: 15px;
}

.list--steps li:before {
  content: "";
  display: block;
  height: 1px;
  background-color: #e0e0e0;
  left: 0;
  right: 50%;
  width: 50%;
  position: absolute;
  top: 15px;
}

.list--steps li:first-child:before {
  display: none;
}

.list--steps li:last-child:after {
  display: none;
}

.steps--number {
  background: #f2f2f2 !important;
  border: 1px solid #e0e0e0;
  color: #8a8a8a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 auto 9px;
  -webkit-transition: all 0.33s;
  transition: all 0.33s;
  will-change: transform;
  position: relative;
  z-index: 1;
}

@-webkit-keyframes steps {
  // 0% {
  //   -webkit-box-shadow: 0 0 0px 0px rgba(217, 35, 55, 0.2);
  //   box-shadow: 0 0 0px 0px rgba(217, 35, 55, 0.2);
  // }
  // 100% {
  //   -webkit-box-shadow: 0 0 5px 5px rgba(217, 35, 55, 0.2);
  //   box-shadow: 0 0 5px 5px rgba(217, 35, 55, 0.2);
  // }
}

@keyframes steps {
  // 0% {
  //   -webkit-box-shadow: 0 0 0px 0px rgba(217, 35, 55, 0.2);
  //   box-shadow: 0 0 0px 0px rgba(217, 35, 55, 0.2);
  // }
  // 100% {
  //   -webkit-box-shadow: 0 0 5px 5px rgba(217, 35, 55, 0.2);
  //   box-shadow: 0 0 5px 5px rgba(217, 35, 55, 0.2);
  // }
}

.steps--label {
  font-family: Rubik;
  font-style: normal;
  line-height: 12px;
  // display: flex;
  // align-items: center;
  // text-align: center;
  // letter-spacing: 0.015em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  /* Greyscale/100 */
  color: #1c1c1c;
}

.col--steps {
  flex: 1;
  text-align: center;
}

.steps--number {
  background: #fbfbfb;
  border: 1px solid #e0e0e0;
  color: #8a8a8a;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 auto 9px;
  transition: all 0.33s;
  will-change: transform;
  position: relative;
  z-index: 1;
}

.list--steps {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none !important;

  li {
    position: relative;
    flex: 0 0 140px;
    text-decoration: none;

    &.active {
      .steps--number {
        animation: steps 1s 1 linear forwards;
        animation-delay: 0.33s;
      }
    }

    &:after {
      content: "";
      display: block;
      height: 1px;
      background-color: #e0e0e0;
      left: 50%;
      right: 0;
      width: 50%;
      position: absolute;
      top: 15px;
    }

    &:before {
      content: "";
      display: block;
      height: 1px;
      background-color: #e0e0e0;
      left: 0;
      right: 50%;
      width: 50%;
      position: absolute;
      top: 15px;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  li::marker {
    display: none !important;
  }
}

::marker {
  display: none !important;
}

.col--steps {
  flex: 1;
  text-align: center;
}

.steps--number {
  background: #fbfbfb;
  border: 1px solid #e0e0e0;
  color: #8a8a8a;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 auto 9px;
  transition: all 0.33s;
  will-change: transform;
  position: relative;
  z-index: 1;
}

textarea {
  resize: none;
}

.descTextArea {
  width: 300px !important;
}
