.notesCard {
  border-radius: 4px;
  background-color: #f2f2f2;
  width: 100%;
  height: 40%;
  padding: 18px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  .notesCard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .notesCard-header-date {
      font-size: 12px;
    }
  }
  .notesCard-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .notesCard-profile-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      .notesCard-profile {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-bottom: 10px;
        background-color: #cccee7;
      }
    }
    .notesCard-text-section {
      height: 70%;
      width: 70%;
      & > textarea {
        height: 80%;
        width: 100%;
        border-radius: 4px;
        border: 2px solid gray;
      }
    }
  }
}
.delete-btn {
  background: #8387b9 !important;
  margin-left: 1rem;
}
