/* Over rides Sync fusion css
/**************** Custom tabs starts ***************************/
.ll-custom-tab {
  .e-toolbar-items {
    margin-left: 0px !important;
  }
}

.ll-custom-tab.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #737ae6 !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: bold;
  font-size: 15px !important;
  line-height: 18px !important;
  /* identical to box height */
  letter-spacing: 0.035em;
  text-transform: capitalize !important;
}

.ll-custom-tab.e-tab .e-tab-header .e-indicator {
  background: #737ae6 !important;
  // right: 78% !important;
  right: 100% !important;
}

.ll-custom-tab.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
  border-bottom: 2px solid #737ae6 !important;
}

.ll-custom-tab.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  display: inherit;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-transform: capitalize !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.035em;
  color: #585757 !important;
}

// .ll-custom-tab.e-tab .e-tab-header::before {
//   border-color: #dee2e6;
//   border-style: solid;
//   border-bottom: 2px solid #dee2e6 !important;
// }

// .ll-tab-width.e-tab .e-tab-header::before {
//     width: 88.5% !important;
// }

.bottom-tab-btn {
  bottom: -80px;
}

.highlight-btn {
  z-index: 9999 !important;
}

.preview-custom-tab .e-toolbar {
  background-color: #eff0f5 !important;
}

.preview-custom-tab {
  .e-toolbar-items {
    margin-left: 6% !important;
    // background: #FFFFFF !important;
    border-radius: 4px 4px 0px 0px;
  }
}

.preview-custom-tab.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  font-family: Rubik !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.035em;
  text-transform: capitalize !important;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #1c1c1c !important;
}

.preview-custom-tab.e-tab
  .e-tab-header:not(.e-vertical)
  .e-toolbar-item.e-active {
  border-radius: 4px;
  background: #ffffff !important;
  border-top: 1px solid #a1a4c2;
  border-left: 1px solid #a1a4c2;
  border-right: 1px solid #a1a4c2;
  border-bottom: 1px solid #fff !important;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.1);
}

.preview-custom-tab.e-tab .e-tab-header .e-indicator {
  background: #fff !important;
  // right: 0% !important;
}

.preview-custom-tab.e-tab .e-tab-header::before {
  border-color: #a1a4c2 !important;
  border-style: solid;
  border-bottom: 1px solid #a1a4c2 !important;
  width: 100% !important;
}

.preview-custom-tab.e-tab .e-tab-header:not(.e-vertical) .e-indicator {
  height: 1px !important;
}

.preview-custom-tab.e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
  border-bottom: 1px solid #a1a4c2 !important;
}

/**************** Custom tabs ends ***************************/

/**************** Custom button starts ***************************/
.opt-btn-dark {
  background: #636799 !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 8px !important;
  color: #fff !important;
}

.opt-btn-light {
  background: #ffffff !important;
  border: 1px solid #636799 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  padding: 8px !important;
  color: #636799 !important;
}

/**************** Custom button ends ***************************/

/************************* Custom table Starts *************************/

.ll-grid {
  &.e-checkbox-wrapper .e-frame,
  .e-css.e-checkbox-wrapper .e-frame.e-uncheck {
    background-color: transparent !important;
    border: 2px solid #ffffff !important;
    border-color: #ffffff !important;
    box-sizing: border-box;
    border-radius: 1px !important;
  }
  &.e-checkbox-wrapper .e-frame,
  .e-css.e-checkbox-wrapper .e-frame {
    background-color: transparent !important;
    border: 2px solid #ffffff !important;
    border-color: #ffffff !important;
    box-sizing: border-box;
    border-radius: 1px !important;
  }
}

.e-columnheader {
  height: 40px !important;
}
.e-grid .e-gridheader .e-sortfilter .e-headercelldiv, 
.e-grid .e-gridheader .e-stackedheadercelldiv, 
.e-grid .e-gridheader .e-headercell 
.e-headercelldiv.e-headerchkcelldiv {
  padding: 5px;
  margin: auto;
}

th.e-headercell {
  background: $table-header-color !important;
  color: #fff !important;
}

.e-grid .e-headercelldiv {
  font-family: Work Sans !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.035em;
  text-transform: uppercase !important;
  color: #ffffff !important;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  border-style: solid;
  border-width: 0;
  font-size: 12px;
  font-weight: 500;
  height: 42px;
  overflow: hidden;
  padding: 0 5px 0;
  position: relative;
  text-align: left !important;
}

.e-grid .e-rowcell {
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  overflow: hidden;
  padding: 8px 12px;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;

  font-family: Rubik !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.0025em;
  color: #1c1c1c !important;
}

.e-rowcell.e-gridchkbox .e-css.e-checkbox-wrapper .e-frame {
  text-overflow: clip;
  border: 1.25px solid #1c1c1c !important;
  box-sizing: border-box;
  border-radius: 1px !important;
  background: transparent !important;
}

.e-rowcell.e-gridchkbox .e-css.e-checkbox-wrapper .e-frame {
  text-overflow: clip;
  border: 1.25px solid #1c1c1c !important;
  box-sizing: border-box;
  border-radius: 1px !important;
  background: transparent !important;
}

.e-checkbox-wrapper .e-check::before,
.e-css.e-checkbox-wrapper .e-check::before {
  content: "\e933";
  // border: 1.25px solid #1C1C1C;
  // box-sizing: border-box;
  // border-radius: 1px;
  color: #000000;
}

/* Pagination */

.e-grid .e-gridpager {
  background-color: #f2f2f2 !important;
}

// .e-pager .e-pagercontainer {
//     background-color: #fff;
//     border-color: #e0e0e0;
//     border-radius: 4px;
//     border-style: solid;
//     border-width: 0;
//     display: inline-block;
//     margin: 5px 6px 5px 18px;
//     overflow: hidden;
//     // display: none;
// }

// .e-pager .e-pagerconstant {
//     visibility: hidden;
//     position: relative;
// }

// .e-pager .e-pagerconstant::after {
//     visibility: visible;
//     content: "Rows per page";
//     margin: 1.5% !important;
//     overflow: hidden;
//     width: auto;
//     font-family: Rubik;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 17px;
//     letter-spacing: 0.02em;
//     color: #1C1C1C;
//     float: left !important;
//     display: flex;
//     justify-content: left !important;
// }

// .e-pager .e-pagerdropdown {
//     height: 32px;
//     overflow: hidden;
//     vertical-align: baseline !important;
//     width: 90px;
//     float: left;
//     margin: 1% !important;
// }

// .e-pager .e-pagesizes {
//     justify-content: left !important;
// }

/* Modal Dialog custom styles */

.tabNameForAddScreen {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;

  /* Greyscale/100 */

  color: #1c1c1c;
}

.modal-title-header {
  width: 95%;
  border-bottom: 1px solid #cccccc;
}

.lblClass {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important ;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  float: right;
  margin-top: 5%;
}
.settingslblClass {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: bold !important ;
  font-size: 15px !important;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  float: right;
  margin-top: 5%;
}
.accessMgmtLabel {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: bold !important ;
  font-size: 15px !important;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  text-align: center !important;
  margin-top: 5%;
}

.form-input-label {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  text-align: center;
  margin-top: 5%;
}

.form-control {
  background: #ffffff !important;
  border: 1px solid #969696 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.02em;
  color: #1c1c1c;

  input {
    height: 36px !important;
  }

  select {
    height: 36px !important;
  }
}

.form-control:focus {
  border-color: none !important;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.e-headercelldiv.e-headerchkcelldiv {
  text-align: left !important;
}
.e-grid {
  border-radius: 6px;
}
.e-grid .e-gridheader {
  border-radius: 6px;
}
.e-grid .e-headercontent {
  border-right-width: 0px !important;
}

/* Date picker Syncfusion custom */

.scanhistory.datepicker-control-section {
  max-width: 246px;
  padding-top: 16px;
}

.selectedListShowDiv {
  height: 150px;
  background-color: #f2f2f2;
}
.selectedListShowDivv {
  height: 100px !important;
  background-color: #f2f2f2;
  overflow-y: scroll;
  width: 102%;
}
.selectedListEditShowDiv {
  height: 325px;
  background-color: #f2f2f2;
}
.selectedListAddShowDiv {
  height: 350px;
  background-color: #f2f2f2;
  overflow: auto;
}

.ll-autocomplete .e-css.e-checkbox-wrapper .e-frame {
  border: 1.25px solid #1c1c1c !important;
  box-sizing: border-box;
  border-radius: 1px !important;
  background: transparent !important;
}
