.font-8 {
  font-size: 8px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
