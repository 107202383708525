@import "./colors";

.eg-btn-success {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    linear-gradient(0deg, #79c24f, #79c24f) !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
}

.eg-btn-primary {
  background: #636799 !important;
  border-radius: 4px !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff !important;

  word-break: break-all !important;
  padding: 1.4rem !important;
  box-shadow: none !important;
}

.eg-btn-primary.e-btn:disabled,
.e-css.e-btn:disabled {
  background: #e8e8e8 !important;
  border-radius: 4px !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #969696 !important;
}

.eg-btn-secondary {
  background: #fcfcfc !important;
  border: 1px solid #636799 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #636799 !important;
  height: 48px !important;
  width: 168px !important;
  word-break: break-all !important;

  box-shadow: none !important;
}

.eg-btn-secondary {
  background: #fcfcfc !important;
  border: 1px solid #636799 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #636799 !important;
  height: 48px !important;
  width: 168px !important;
  word-break: break-all !important;

  box-shadow: none !important;
  &.e-btn.e-outline:disabled,
  .e-css.e-btn.e-outline:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
}

.eg-btn-secondary:hover {
  box-shadow: 0px 0px 4px 1px #a5a5a5 !important;
}

.eg-btn-primary:hover {
  box-shadow: 0px 0px 4px 1px #a5a5a5 !important;
}

.eg-btn-warning {
  background: #8c4d3f !important;
  border: 1px solid #8c4d3f !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #fff !important;
  height: 48px !important;
  width: 168px !important;
  word-break: break-all !important;

  &.e-btn.e-outline:disabled,
  .e-css.e-btn.e-outline:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
}

/* Link buttons */

.eg-btn-primary-link {
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 17px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.02em !important;
  text-decoration-line: underline !important;
  color: #636799 !important;
  &.e-btn.e-outline:disabled,
  .e-css.e-btn.e-outline:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
}

.eg-btn-primary-link:hover {
  background: none !important;
}
