.scan-root-printer-status {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        #8c4d3f;
    border-radius: 4px;

    img {
        background: none;
    }

    span {
        padding-right: 16px;
    }
}
