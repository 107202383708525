.scan-report-section {
    .reports-button {
        background: none;
        border: none;
        padding: 0 8px;
        box-shadow: none;
    }

    .create-report {
        padding: 0 !important;
    }

    .run-report {
        margin: 0 8px !important;
    }

    .reports-hr {
        border: 1px solid #cccccc;
        margin: 0;
    }

    .back-to-all {
        color: #1c1c1c;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.0025em;
        text-align: left;
    }

    .reports-completed-btn {
        background: #79c24f !important;
        border-radius: 4px !important;
        border: none !important;
        padding: 8px !important;
        color: #000000 !important;
        width: 184px;
    }

    ul.e-ccul-ele.e-cc {
        text-align: left !important;
    }

    h2 {
        text-align: left;
    }

    .report-form-fields {
        margin: 0 20px;
        text-align: left;
    }

    .error-message {
        width: 100%;
        margin-top: 0.25rem;
        color: red;
    }

    .preview-report {
        margin: 0 !important;
        padding: 0 !important;
        color: #ffffff !important;

        .e-tbar-btn-text {
            color: #fff !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            padding: 0 !important;
        }

        button {
            background: #636799 !important;
            border-radius: 4px !important;
            border: none !important;
            padding: 3px 8px !important;
            margin: 0 !important;
        }
    }
}
