@mixin H5 {
  //styleName: Header/H5;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin touchButton {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
}

@mixin bodyStandard {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  color: #1c1c1c;
}
