.report-preview-card {
    background: #eff0f5 !important;
    border-radius: 4px;
    height: calc(100vh - 250px);
    border: 2px solid #b4b5c8;
}

.reports-preview-details {
    background: #ffffff;
    height: calc(100vh - 355px);
    overflow-y: auto;
}

.paramsList {
    height: calc(100vh - 440px);
    overflow-y: scroll;
}

.settingsList {
    height: calc(100vh - 470px);
    overflow-y: scroll;
}

.noParamsList {
    height: calc(100vh - 440px);
    overflow-y: scroll;
}

.error-text {
    font-size: 12px;
}

button[disabled].report-dwnload-btn {
    cursor: default;
    opacity: 0.7 !important;
}

.mandatory-icon {
    color: red;
}
