.keyboardContainer {
    position: absolute;
    z-index: 99999;
    width: 100%;
    min-width: 496px;

    .alpha-numeric-theme {
        min-width: 1024px;
        left: -115%;
        position: absolute;

        .hg-button {
            height: 80px;
        }
    }
}

.keyboardContainer.hide-me {
    display: none;
}

.input-field.upc-input-field {
    background: #ffffff !important;
    border: 1px solid #969696 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    display: flex;
    padding: 2px;

    input {
        background: #ffffff !important;
        box-sizing: border-box !important;
        font-family: Rubik !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px !important;
        line-height: 17px !important;
        letter-spacing: 0.02em;
        color: #1c1c1c;
        border: transparent;
        width: 100%;
        padding: 4px 10px;
    }

    .keyboardContainer {
        top: 58px;
    }
}
