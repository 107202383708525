.scan-controls {
    padding: 1rem;
}

.border-1 {
    // border:1px solid;
    padding: 2rem;
    box-sizing: border-box;
    margin-top: 1rem;
}

.scanitem {
    background-color: #979bd4;
}

.food {
    background-color: #c9e7b9;
}

.hba {
    background-color: cornflowerblue;
}

.inbound-box,
.inbound-box:hover {
    width: 111px;
    height: 98px;
    font-family: Rubik;
    font-weight: 500;
    text-transform: uppercase;
    background: rgba(99, 103, 153, 1) !important;

    &.trash-button {
        background: rgba(140, 77, 63, 1) !important;
        width: 115%;
    }
}

span.item-description {
    text-align: right;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.scan-settings-icon {
    position: relative;
    bottom: 11.7rem;
}

.scanBox {
}

span.item-vendor {
    text-align: left;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.scanText {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #1c1c1c;
}

.goalPart {
    border: 2px solid #b9babd;
    padding-top: 7px;
    border-radius: 5px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.8)
        ),
        #636799;
}

.totalPage {
    background: #e8dbd9;
}

.inboundBox {
    border: 1px solid #636799;
    padding: 14px;
    background: #636799;
    color: white;
    border-radius: 4px;
    font-family: Rubik;
    font-size: 12px;
}

.closeOutboundBox {
    border: 1px solid #e8e8e8;
    padding: 14px;
    background: #e8e8e8;
    color: #969696;
    border-radius: 4px;
    font-family: Rubik;
    font-size: 12px;
}

.upcDetailsRowOne {
    font-size: 1.3rem;
    padding: 1rem;
    border-radius: 6px;
    //     background: linear-gradient(
    // 0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #619b3f;
}

.upcDetailsRowTwo {
    font-size: 1.3rem;
    padding: 1rem;
    border-radius: 6px;
    background: white;
}

.alertBox {
    // background: #619b3f;
    padding: 2rem;
    text-align: center;
    margin-top: 0.7rem;
}

.alertText {
    font-family: Rubik;
    font-weight: normal;
    font-size: 4rem;
    text-align: center !important;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: #ffffff;
    box-sizing: border-box;
}

.scanmode-warning {
    position: absolute !important;
    width: 95% !important;
    text-align: left;
    padding: 0.2rem !important;
    text-transform: uppercase;
    color: #fff !important;
}

.fw-600 {
    font-weight: 600;
}

.scanBody {
    padding-top: 5rem;
    font-size: 24px !important;
}

.switchText {
    padding-left: 1rem;
    font-family: Rubik;
    font-size: 15px;
    line-height: 3rem;
    letter-spacing: 0.035em;
    color: #1c1c1c;
    top: 0.3rem;
    position: relative;

    /* Greyscale/100 */

    color: #1c1c1c;
}

.inboundBoxHeading {
    text-align: left;
}

.inboundBoxHeadingText {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        #636799;
    padding: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    color: #1c1c1c;
    border-radius: 7px 7px 0px 0px;
}

.customerGroupInboundDiv {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
        ),
        #636799;
    border-radius: 0px 4px 0px 0px;
    padding: 10px;
    text-align: left;
}

.storeInboundDiv {
    background: white;
    border-radius: 0px 4px 0px 0px;
    padding: 10px;
    text-align: left;
}

.inboundBoxContent {
    border: 1px solid #b9babb;
    border-radius: 0px 5px 5px 5px;
    font-size: 16px;
}

.p5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.p2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.scan-inbound {
    width: 100%;
    margin: 0px auto;
}

.m6 {
    margin-top: 6rem;
}

.outbound-box th {
    color: white;
    background-color: #636799;
}

/*progress bar*/

.progress-wrapper {
    display: inline-block;
    background: #f8f8f8;
    border-radius: 10px;
    height: 10px;
    width: 100%;
}

.demo-css .progress-inner {
    border-radius: 10px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        linear-gradient(0deg, #636799, #636799);

    height: 10px;
    transition: width 400ms cubic-bezier(0.08, 0.82, 0.17, 1);
}

.progress-info {
    display: inline-block;
    margin-left: 10px;
    color: #1c1c1c;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}
.qty-css {
    margin-top: 10px;
}
p.tab-title {
    font-size: 25px;
    text-align: left;
    margin-left: 2rem;
}

.goal-progress {
    border-radius: 8px;
    // background: linear-gradient(
    //         0deg,
    //         rgba(255, 255, 255, 0.8),
    //         rgba(255, 255, 255, 0.8)
    //     ),
    //     linear-gradient(0deg, #636799, #636799);

    p {
        font-size: 15px;
        margin-top: 10px;
        color: #000000;
        font-weight: 500;
    }
}

.scan-progress {
    height: 10px !important;
    margin-bottom: 0px !important;
    margin-top: 1.4rem;
    width: 70%;
    display: inline-block;
}

.label-name {
    font-size: 1.4rem;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    /* line-height: 17px; */
    display: flex;
    align-items: center;
    letter-spacing: 0.035em;
    /* color: #1C1C1C; */
}

.outbound-box {
    // border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0px;
}

.fb-15 {
    font-size: 14px;
    font-weight: 400;
    font-family: Rubik;
    font-family: Rubik;

    line-height: 18px;

    letter-spacing: 0.035em;

    /* Greyscale/100 */

    color: #1c1c1c;
}

//karthik css
.scandiv {
    // margin: 1em 0em 0em -5em;
    // margin: 1em 0em 0.7em -5em;;
    margin: 1em 0em 3em -3.5em;
}

input[type='checkbox'],
input[type='radio'] {
    margin: 6px -1px 0;
    line-height: normal;
}

.upcdiv {
    margin: 0em 0em 0em -3em;
}

.txtUPC {
    // padding: 2.5rem;
    height: 4.8rem;
}

.txtUPC1 {
    padding: 0px 1px 11px 1px;
}

.btnUPC {
    color: #fff;
    background-color: #636799;
    border-color: #636799;
}

input.form-control.txtUPCNum {
    padding: 28px 5px 26px 13px;
    width: 82px;
    text-align: center;
}

.btnMinus {
    color: #fff;
    background-color: #636799;
    border-color: #636799;
    padding: 21px 3px 28px 5px;
}

.btnPlus {
    color: #fff;
    background-color: #636799;
    border-color: #636799;
    padding: 8px 4px 14px 8px;
}

.divBox {
    // padding: 2px 14px 0px 0px;
    // margin: 0px 0px 3px -12px;
    //padding: 0% 4% 0% 0%;
    padding: 0.1% 1% 0% 0%;
    margin: 0% -1% 0% 0%;
}

.imgPlus {
    vertical-align: top;
    display: list-item;
    text-align: center;
    /* width: 13px; */
    padding: 21px 0px 3px 4px;
}

.btnUPCbody {
    padding: 0rem 0rem 0rem 0rem;
}

.imgToparrow {
    vertical-align: top;
    display: list-item;
    text-align: center;
    width: 28px;
    padding: 1px 9px 1px 9px;
    margin: 0px 0px -2px 0px;
}

.imgdownarrow {
    vertical-align: top;
    display: list-item;
    text-align: center;
    width: 13px;
    padding: 3px 0px 0px 2px;
}

.imgPlus {
    vertical-align: center;
    display: list-item;
    text-align: center;
    width: 30px;
    padding: 3px 1px 5px 3px;
}

.imgMinus {
    vertical-align: center;
    display: list-item;
    text-align: center;
    width: 30px;
    padding: 19px 2px 8px 0px;
}

.btnUPCMinus {
    padding: 10px 10px 10px 10px;
    background-color: #636799;
    border-color: #636799;
}

.btnUPCPlus {
    padding: 9px 10px 10px 10px;
    background-color: #636799;
    border-color: #636799;
}

.btn-primary:hover {
    color: #fff;
    background-color: #636799;
    border-color: #636799;
}

.pR-0 {
    padding-right: 78px;
}

.pR-1 {
    padding-left: 33%;
}

.pR-2 {
    padding-left: 23%;
}

.col-sm-6.mt-2rem.btnCloseForce {
    margin-top: 5rem;
    // margin-bottom: 47px;
}

.btnclose {
    margin-top: 0.7rem;
}

.inbound-box,
.inbound-box:hover {
    width: 100%;
    height: 101px;
    font-family: Rubik;
    font-weight: 600;
    text-transform: uppercase;
    background: #636799 !important;
}

.inbound-boxClose {
    width: 111%;
    height: 101px;
    font-family: Rubik;
    font-weight: 600;
    text-transform: uppercase;
    background: #636799 !important;
    margin: 0px 23px 29px -12px;
}

.col-sm-12.mt-1rem.pl-1 {
    width: 106%;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .switchText {
        padding-left: 1rem;
        font-family: Rubik;
        font-size: 10px;
    }
    .goal-progress p {
        font-size: 13px;
        margin-top: 6px;
    }
    .btnUPCMinus {
        padding: 10px 0px 12px 3px;
        background-color: #636799;
        border-color: #636799;
    }
    .btnUPCPlus {
        padding: 10px 0px 12px 3px;
    }
    .btnUPC {
        padding: 6px 0px 12px 3px;
    }
    input.form-control.txtUPCNum {
        padding: 28px 5px 26px 13px;
        width: 52px;
        text-align: center;
    }
    .inbound-box,
    .inbound-box:hover {
        width: 143%;
        height: 101px;
        font-family: Rubik;
        font-weight: 600;
        text-transform: uppercase;
        background: #636799 !important;
        margin: 1px 4px 3px 0px;
    }
    .inbound-boxClose {
        width: 173%;
        height: 101px;
        font-family: Rubik;
        font-weight: 600;
        text-transform: uppercase;
        background: #636799 !important;
        margin: 25px 0px 70px -12px;
    }
    .inbound-box.trash-button,
    .inbound-box:hover.trash-button {
        background: #8c4d3f !important;
        width: 185%;
        margin-top: 25px;
    }
    .label-name {
        font-size: 1.3rem;
    }
    .pR-0 {
        padding-right: 29px;
    }
    .upcDetailsRowTwo {
        font-size: 14px;
        padding: 11px 0px 13px 0px;
        border-radius: 6px;
        background: #ffffffe5;
    }
    .pl-3 {
        padding-left: 0rem !important;
    }
    .upcDetailsRowOne {
        font-size: 13px;
        padding: 16px;
        border-radius: 6px;
    }
}

@media screen and (max-width: 1380px) and (min-width: 1025px) {
    .goal-progress p {
        font-size: 13px;
        margin-top: 6px;
    }
    .switchText {
        padding-left: 0rem;
        font-family: Rubik;
        font-size: 11px;
        line-height: 8px;
        letter-spacing: 0.02em;
        color: #1c1c1c;
        color: #1c1c1c;
    }
    .btnUPCMinus {
        padding: 10px 0px 12px 3px;
        background-color: #636799;
        border-color: #636799;
    }
    .btnUPCPlus {
        padding: 10px 0px 12px 3px;
    }
    .btnUPC {
        padding: 6px 0px 12px 3px;
    }
    input.form-control.txtUPCNum {
        padding: 28px 5px 26px 13px;
        width: 52px;
        text-align: center;
    }
    .inbound-box,
    .inbound-box:hover {
        width: 96px;
        height: 101px;
        font-family: Rubik;
        font-weight: 600;
        text-transform: uppercase;
        background: #636799 !important;
        margin: 1px 4px 3px 0px;
    }
    .inbound-boxClose {
        width: 100px;
        height: 101px;
        font-family: Rubik;
        font-weight: 600;
        text-transform: uppercase;
        background: #636799 !important;
        margin: 2px 0px 62px -12px;
    }
    .inbound-box.trash-button,
    .inbound-box:hover.trash-button {
        background: #8c4d3f !important;
        width: 100px;
        margin-top: 2px;
    }
    .label-name {
        font-size: 1.3rem;
    }
    .pR-0 {
        padding-right: 29px;
    }
    .upcDetailsRowTwo {
        font-size: 13px;
        padding: 11px 0px 13px 0px;
        border-radius: 6px;
        background: #ffffffe5;
    }
    .pl-3 {
        padding-left: 0rem !important;
    }
    .upcDetailsRowOne {
        font-size: 14px;
        padding: 16px;
        border-radius: 6px;
    }
    .alertBox {
        padding: 1rem;
        text-align: center;
    }
    .col-sm-6.mt-2rem.btnCloseForce {
        margin-top: 44px;
        margin-bottom: 31px;
        padding: 10px;
    }
}

.btnBox {
    height: 48px !important;
    width: 168px !important;
}

.btninboundBox,
.btninboundBox:hover {
    width: 118% !important;
}

img.imgsetting {
    float: right !important;
    cursor: pointer;
}

.switch {
    position: relative !important;
    display: inline-block !important;
    width: 47px !important;
    height: 20px !important;
}

.switch input {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
}

.slider {
    position: absolute !important;
    cursor: pointer !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background-color: #ccc !important;
    -webkit-transition: 0.4s !important;
    transition: 0.4s !important;
}

.slider:before {
    position: absolute !important;
    content: '' !important;
    height: 19px !important;
    width: 19px !important;
    left: 1px !important;
    bottom: 1px !important;
    background-color: white !important;
    -webkit-transition: 0.4s !important;
    transition: 0.4s !important;
}

input:checked + .slider {
    background-color: #2196f3 !important;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3 !important;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px) !important;
    -ms-transform: translateX(26px) !important;
    transform: translateX(26px) !important;
}

.txtdbtrsn {
    white-space: nowrap;
    text-align: left;
}

.txtunit {
    padding-left: 6%;
    white-space: nowrap;
}

//disposition css
.warehouse-location-heading {
    height: calc(100% - 17vh);
    padding-top: 2.6rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
}

.info-icon {
    margin-right: 0.9rem;
}

.sub-disposition {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(0deg, #f62817, #f62817);
    color: #fff;
    font-size: 1.4rem;
    padding: 0.3rem 3rem 0.3rem 3rem;
    border-radius: 4px;
}

.box {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.8)
        ),
        linear-gradient(0deg, #636799, #636799);
}

.bin {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.8)
        ),
        linear-gradient(0deg, #737ae6, #737ae6);
}

.trash {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        linear-gradient(0deg, #8c4d3f, #8c4d3f);
}

.hazardous {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        linear-gradient(0deg, #eba6a9, #eba6a9);
}

.salvage-hba-gm {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.8)
        ),
        linear-gradient(0deg, #737ae6, #737ae6);
}

.meds {
    background: rgba(204, 204, 204, 1) !important;
}

.salvage-hba-food {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        linear-gradient(0deg, #79c24f, #79c24f);
}

.hold-special-review {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        linear-gradient(0deg, #f2ca52, #f2ca52);
}

.donate-non-food {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        linear-gradient(0deg, #737ae6, #737ae6);
}

/*Scan Modes*/
input[type='checkbox'].toggle {
    opacity: 0;
    position: absolute;
    left: -9000px;
    top: -9000px;
}

input[type='checkbox'].toggle + label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.035em;
    text-align: center;
}

/*trash mode*/

input[type='checkbox']#trashMode + label::before {
    content: '';
    width: 3.6em;
    height: 1em;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;
    border-radius: 1em;
    margin-right: 0.3em;
    transition: background-color 200ms ease-in-out;
}

input[type='checkbox']#trashMode + label::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: '\2715';
    font-size: 0.899em;
    height: 1em;
    left: 0em;
    width: 2.2em;
    height: 1.8em;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;
    color: white;
    border-radius: 50%;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type='checkbox']#trashMode:focus + label::before {
    //outline: 1px solid black;
}

input[type='checkbox']#trashMode:checked + label::before {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;
}

input[type='checkbox']#trashMode:checked + label::after {
    content: '\2713';
    transform: translateX(100%);
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;
}

.trash-mode {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(0deg, #8c4d3f, #8c4d3f);
    // background: #8c4d3f;
}

/*trash mode*/

/*bin mode*/

input[type='checkbox']#binMode + label::before {
    content: '';
    width: 3.6em;
    height: 1em;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;

    border-radius: 1em;
    margin-right: 0.5em;
    transition: background-color 200ms ease-in-out;
}

input[type='checkbox']#binMode + label::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: '\2715';
    font-size: 0.899em;
    height: 1em;
    left: 0em;
    width: 2.2em;
    height: 1.8em;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;
    color: white;
    border-radius: 50%;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type='checkbox']#binMode:focus + label::before {
    //outline: 1px solid black;
}

input[type='checkbox']#binMode:checked + label::before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(0deg, #79c24f, #79c24f);
}

input[type='checkbox']#binMode:checked + label::after {
    content: '\2713';
    transform: translateX(100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(0deg, #79c24f, #79c24f);
}

.bin-mode {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(0deg, #79c24f, #79c24f);
    // background-color: #79c24f;
}

/*bin mode*/

/*no credit mode*/

input[type='checkbox']#noCreditMode + label::before {
    content: '';
    width: 3.6em;
    height: 1em;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;

    border-radius: 1em;
    margin-right: 0.5em;
    transition: background-color 200ms ease-in-out;
}

input[type='checkbox']#noCreditMode + label::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: '\2715';
    font-size: 0.899em;
    height: 1em;
    left: 0em;
    width: 2.2em;
    height: 1.8em;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;
    color: white;
    border-radius: 50%;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type='checkbox']#noCreditMode:focus + label::before {
    //outline: 1px solid black;
}

input[type='checkbox']#noCreditMode:checked + label::before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(0deg, #f2ca52, #f2ca52);
}

input[type='checkbox']#noCreditMode:checked + label::after {
    content: '\2713';
    transform: translateX(100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(0deg, #f2ca52, #f2ca52);
}

.nocredit-mode {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(0deg, #f2ca52, #f2ca52);
    // background: #f2ca52;
}

/*no credit mode*/

/*audit mode*/

input[type='checkbox']#auditMode + label::before {
    content: '';
    width: 3.6em;
    height: 1em;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;

    border-radius: 1em;
    margin-right: 0.5em;
    transition: background-color 200ms ease-in-out;
}

input[type='checkbox']#auditMode + label::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: '\2715';
    font-size: 0.895em;
    height: 1em;
    left: 0em;
    width: 2.2em;
    height: 1.8em;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #8c4d3f;
    color: white;
    border-radius: 50%;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type='checkbox']#auditMode:focus + label::before {
    //outline: 1px solid black;
}

input[type='checkbox']#auditMode:checked + label::before {
    background: #737ae6;
}

input[type='checkbox']#auditMode:checked + label::after {
    content: '\2713';
    transform: translateX(100%);
    background: #737ae6;
}

.audit-mode {
    background: #737ae6;
}

/*audit mode*/

/*Grid delete*/

.clear-item {
    background: url('../../icons/Close_Delete.svg') !important;
    width: 29px;
    height: 25px;
    background-repeat: no-repeat !important;
    box-shadow: none !important;
}

.scanned-content-box {
    margin-top: 1.99rem;
}

/*toaster for modes*/
.trashmode {
    background-position: center;
    background: url('../../icons/info.svg') #542e26 no-repeat 2.6em 0.4em !important;
    color: #fff !important;
    width: 100%;
    max-width: 29vw !important;
    top: 5rem;
    right: 14rem;
}

.binmode {
    background-position: center;
    background: url('../../icons/info.svg') #497530 no-repeat 3.9em 0.4em !important;
    color: #fff !important;
    width: 100%;
    max-width: 29vw !important;
    top: 5rem;
    right: 14rem;
}

.nocreditmode {
    background-position: center;
    background: url('../../icons/info.svg') #c2a242 no-repeat 0.9em 0.4em !important;
    color: #fff !important;
    width: 100%;
    max-width: 33vw !important;
    top: 5rem;
    right: 14rem;
}

.auditmode {
    background-position: center;
    background: url('../../icons/info.svg') #737ae6 no-repeat 0.4em 0.4em !important;
    color: #fff !important;
    width: 100%;
    max-width: 31vw !important;
    top: 5rem;
    right: 14rem;
}

/*toaster for modes*/
/******anvesh***/
.inbound-box-heading {
    margin-left: 13rem;
}

.binMode {
    margin-right: 7rem;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

span.items {
    font-weight: 100;
    color: #434242;
}

.btnUPCdiv {
    padding-left: 2.2rem;
}

.btnUPCPlusdiv {
    padding-left: 1.8rem;
}

.btnUPCfirstdiv {
    padding-left: 0.5rem;
}

.btnUPCMinusdiv {
    padding-left: 1.2rem;
}

.alertBoxName {
    font-family: 'Rubik';
    /* font-style: normal; */
    /* font-weight: 500; */
    /* font-size: 15px; */
    /* line-height: 18px; */
    /* display: flex; */
    /* align-items: center; */
    /* letter-spacing: 0.035em; */
    color: #1c1c1c;
    right: 0.3rem;
}

.auditModediv {
    padding-left: 3.4rem;
}

.scan-controls-table {
    width: 95%;
}

.goal-progress {
    margin-left: -1.5rem;
}

/*sacn popup changes***/
.popupName {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #1c1c1c;
}

.pwdtxt {
    margin-left: 7rem;
}

.Unlabeledtxt {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    align-items: center;
    text-align: right;
    letter-spacing: 0.035em;
    color: #1c1c1c;
}

.store-under {
    background: #e8e8e8;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 2rem;
}

.hazardous-btn {
    background: #d1b8b2 !important;
    width: 115%;
    border: 1px solid #d1b8b2;
    color: #8c4d3f;
}

.hazardous-btn:hover {
    background: #d1b8b2 !important;
    width: 115%;
    border: 1px solid #d1b8b2;
    color: #8c4d3f;
}

.e-dialog .e-dlg-content {
    color: red;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
}

//RajaRavanam css for virtual keyboard

.hg-theme-default {
    background-color: #f5f5f5;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    touch-action: manipulation;
    width: 100%;
}

.hg-theme-default .hg-row {
    display: flex;
}

.hg-theme-default .hg-row:not(:last-child) {
    margin-bottom: 5px;
}

.hg-theme-default .hg-button.hg-standardBtn {
    width: 20px;
}

.hg-theme-default .hg-row .hg-button:not(:last-child) {
    margin-right: 5px;
}

.hg-theme-default .hg-button {
    webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 5px;
    box-shadow: 0 0 3px -1px darkgray;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 80px;
    justify-content: center;
    padding: 10px;
}

.hg-theme-default .hg-button {
    flex-grow: 1;
}

.hg-button {
    font-size: 36px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn='.com'] {
    max-width: 100px;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn='@'] {
    max-width: 85px;
}

.hg-button.hg-standardBtn:active {
    background-color: lightgray;
    transform: translateZ(10px);
}

#mydiv {
    position: absolute;
    z-index: 9;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center;
}

#mydivheader {
    padding: 10px;
    cursor: move;
    z-index: 10;
    background-color: #2196f3;
    color: #fff;
}

.scan-data-table {
    top: -4rem;
}

.outbound-box-status {
    .outbound-box-status-alert {
        color: #000000;
        text-align: center;

        h3 {
            font-size: 36px;
        }
    }

    .e-footer-content {
        button {
            background: #636799 !important;
            padding: 16px;
            width: 96px;
            font-size: 24px;
        }
    }
}
