.creditmemo-table {
  width: 15%;
  max-width: 750px;
  margin: 1rem;
  margin: 0 auto;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid #dedede;
  overflow: hidden;
  .creditmemo-table-header {
    background-color: #c5aaa4;
    display: flex;
    padding: 10px;
    justify-content: center;
    width: 100%;
  }
  .creditmemo-table-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    & > input {
      border: none;
      text-align: center;
      width: 100%;
    }
  }
}
.credit-memo-input-table {
  width: 15%;
  max-width: 750px;
  margin: 1rem;
  margin: 0 0 0 10px;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid #dedede;
  overflow: hidden;
  box-sizing: border-box;
  .credit-memo-input-table-header {
    background-color: #c5aaa4;
    display: flex;
    padding: 12px;
    justify-content: center;
    width: 100%;
  }
  .credit-memo-input-table-warpper {
    margin: 55px 0;
    .credit-memo-input-table-item {
      width: 100%;
      display: flex;
      // flex-direction: column;
      padding: 6px 10px;
      align-items: center;
      justify-content: center;
      & > input {
        border: none;
        text-align: center;
        width: 88%;
      }
    }
  }
}
.backToCM {
  margin-top: 0.7rem;
  font-size: 13px;
  cursor: pointer;
}
