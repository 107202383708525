.statusbutton_Draft {
  position: relative;
  padding: 7px;
  background: #f7df97 !important;
  border-radius: 4px;
  width: 198px;
  height: 36px;
  text-align: center;
  border: 25px;
  font-size: 16px;
}
.statusbutton_ReadyforSalvager {
  position: relative;
  padding: 7px;
  background: #79c24f !important;
  border-radius: 4px;
  width: 198px;
  height: 36px;
  text-align: center;
  border: 25px;
  font-size: 16px;
}
.statusbutton_ReadyforExtract {
  position: relative;
  padding: 7px;
  background: #79c24f !important;
  border-radius: 4px;
  width: 198px;
  height: 36px;
  text-align: center;
  border: 25px;
  font-size: 16px;
}
.statusbutton_FinalClose {
  position: relative;
  padding: 7px;
  background: #dacac7 !important;
  border-radius: 4px;
  width: 198px;
  height: 36px;
  text-align: center;
  border: 25px;
  font-size: 16px;
}
.statusbutton_approved {
  position: relative;
  padding: 7px;
  background: #399b00 !important;
  border-radius: 4px;
  width: 198px;
  height: 36px;
  text-align: center;
  border: 25px;
  font-size: 16px;
}
.statusbutton_Approved {
  position: relative;
  background-color: #e3e4fa !important;
  padding: 7px;
  font-family: Rubik;
  text-align: center;
  border: 25px;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 0.0025em;
  width: 198px;
  height: 36px;
  font-size: 16px;
}
.statusbutton_InProcess {
  position: relative;
  background-color: #c9e7b9 !important;
  padding: 7px;
  font-family: Rubik;
  text-align: center;
  border: 25px;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 0.0025em;
  width: 198px;
  height: 36px;
  font-size: 16px;
}
.statusbutton_rejected {
  background-color: #525583 !important;
  padding: 7px;
  font-family: Rubik;
  border: 25px;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  width: 198px;
  height: 36px;
  font-size: 16px;
}
.statusbutton_Rejected {
  background-color: #c1c2d6 !important;
  padding: 7px;
  font-family: Rubik;
  border: 25px;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  width: 198px;
  height: 36px;
  font-size: 16px;
}
.statusbutton_Cancelled {
  background-color: #d1b8b2 !important;
  padding: 7px;
  font-family: Rubik;
  border: 25px;
  color: #57433e;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  width: 198px;
  height: 36px;
  font-size: 16px;
}
.statusbutton_PickupManualOverride {
  background: #e3e4fa;
  padding: 7px;
  font-family: Rubik;
  border: 25px;
  color: #434779 !important;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  width: 198px;
  height: 36px;
  font-size: 16px;
}
.statusbutton_Picked {
  position: relative;
  padding: 7px;
  background: #c1c2d6;
  border-radius: 4px;
  width: 198px;
  height: 36px;
  text-align: center;
  border: 25px;
  font-size: 16px;
}
.statusbutton_Released {
  position: relative;
  padding: 7px;
  background: #76f3b2;
  border-radius: 4px;
  width: 198px;
  height: 36px;
  text-align: center;
  border: 25px;
  font-size: 16px;
}

#Draft {
  background-color: #f7df97;
  border-radius: 5px;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
}
#PickupManualOverride {
  background-color: #e3e4fa;
  color: #434779;
  border-radius: 5px;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
}
#ReadyforExtract {
  background-color: #b35dfa;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
}
#ReadyforSalvager {
  background-color: #79c24f;
  border-radius: 5px;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
}
#Approved {
  background-color: #e3e4fa;
  border-radius: 5px;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
  font-weight: 500;
  color: #707072;
}
#InProcess {
  background-color: #c9e7b9;
  border-radius: 5px;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
  font-weight: 500;
  color: #304f1f;
}
#Rejected {
  background-color: #c1c2d6;
  border-radius: 5px;
  display: flex;
  text-align: center;
  width: 190px;
  margin-bottom: 4px;
  font-weight: 500;
  color: #4a4b58;
}
#Cancelled {
  background-color: #d1b8b2;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
  font-weight: 500;
  color: #6f4b43;
}
#FinalClose{
  background-color: #dacac7;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
  font-weight: 500;
  color: #786763;
}
#Picked {
  background-color: #c1c2d6;
  border-radius: 5px;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
}
#Released {
  background-color: #0ed0a3;
  border-radius: 5px;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
}
.statusbutton_PickupAuto {
  background: #e3e4fa;
  padding: 7px;
  font-family: Rubik;
  border: 25px;
  color: #434779 !important;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  width: 198px;
  height: 36px;
  font-size: 16px;
}

.statusbutton_PickupHold {
  background: #e3e4fa;
  padding: 7px;
  font-family: Rubik;
  border: 25px;
  color: #434779 !important;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  width: 198px;
  height: 36px;
  font-size: 16px;
}
#PickupHold {
  background-color: #8c3f6e;
  color: white;
  border-radius: 5px;
  text-align: center;
  display: flex;
  width: 190px;
  margin-bottom: 4px;
}
