.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  transition: all 0.5s ease-in;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content-wrapper {
    background-color: white;
    border-radius: 4px;
    width: 80vw;
    //max-height: 90vh;
    position: absolute;
    box-shadow: 2px 2px 10px #777;
    left: 50%;
    z-index: 10001;
    transform: translate(-50%, -50%);
    padding: 4rem;
    top: 50%;
    transition: all 0.5s ease-in;

    .close-button {
      float: right;
      z-index: 10000;
      cursor: pointer;

      &:hover {
        background-color: lightgray;
      }

      & > span {
        padding: 1rem;
        text-align: start;
        border-radius: 50%;
        line-height: 0;
      }
    }
  }

  .modal-content-wrapper.inbound-modal {
    top: 37%;
  }
}

.modal-header-title {
  border-bottom: 1px solid #cccccc;
}
