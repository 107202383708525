+/*Add forms styles*/
.lblClass {
  font-weight: bold;
  font-size: 13px;
}
.cursor-pointer {
  cursor: pointer;
}
.addIconImgAltAddress {
  border: 1px solid;
  border-radius: 6px;
  padding: 2px;
}
.imagePreviewDiv {
  text-align: center;
  margin-top: 4rem;
}

.imagePreview {
  border: 1px solid #e0e1eb;
  border-radius: 51px;
  padding: 9px;
}
.valuChange {
  border-bottom: 1px solid #b9b9b9;
}
.lblClassRuleException {
  font-size: 12px;
}

.tabNameRow {
  border-bottom: 1px solid #cecece;
}
.userAddIcon {
  background: #ccc;
  border-radius: 70px;
  width: 125px;
  height: 125px;
}
.btnAddInPopups {
  background: #636799 !important;
  border-radius: 4px !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff !important;

  word-break: break-all !important;
  padding: 1.4rem !important;
  box-shadow: none !important;
}
.btnContinueDisabled {
  padding: 14px !important;
}
.btnCancelInPopups {
  padding: 14px !important;
  border: 1px solid #636799 !important;
  box-shadow: none !important;
  color: #636799 !important;
}

.userimagePreviewDiv {
  float: left !important;
  margin-top: 0 rem;
}

.userPreviewIcon {
  border-radius: 70px;
  background-color: #ccc !important;
  height: 125px;
  margin: 5px;
}

input.form-control.boxShadow-None.border-none.cursor-pointer.txtEditFieldFocus.boxShadow-None.pl-0 {
  text-transform: capitalize;
}
.accMgmtFields {
  text-transform:none !important;
}
