@import "../../../styles/global.scss";

.creditmemo-tables-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.creditmemo-container {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  flex-wrap: wrap;
  .input-control {
    display: flex;
    width: 30% !important;
    @include InputStyle;
    .input-control-label {
      width: 25% !important;
      font-weight: 500;
      text-align: start !important;
    }
    .input-control-value {
      width: 69% !important;
      text-align: center !important;
      border: 1px solid gray !important;
      border-radius: 4px;
      height: 79%;
      padding-top: 6px;
      font-weight: 400;
    }
  }
  .radio-group-invoice {
    display: flex;
    width: 100%;
    justify-content: end;
    font-weight: 700;
    padding: 10px 0;
    margin: 10px 25px;
    & > span {
      margin-right: 4rem;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 18px;
    }
  }
}
.donotFrzInvoice {
  justify-content: normal !important;
  margin: 0px !important;
}
.donotFrzInvoiceTxt {
  margin-right: 1rem !important;
  font-size: 14px !important;
  line-height: 18px;
  font-family: Rubik !important;
  font-weight: 600 !important;
}
.New-creditmemo-screen {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 20px;
  padding: 2rem 3rem 4rem 7rem;
  .invoice-list-dropdown {
    width: 30%;
    position: absolute;
    top: 20;
    left: 20;
    margin-top: 10rem;
  }
}
.donotFrzCMTxt {
  font-weight: 500 !important;
  margin-right: 1rem;
}
.extrctOracTextCMDiv {
  text-align: left;
}
.inpextrctOracTextCM {
  font-size: 14px;
  color: #555151;
  font-weight: 500;
}
.doNotFrzDivCM {
  padding-left: 2.8rem;
}
.invceDatCM {
  padding-left: 6rem;
}
.textAlign-left  {
  text-align: left;
}
