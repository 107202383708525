.header-name-access-sub {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  color: #1c1c1c;
  float: left;
  margin: 0;
  padding-top: 2%;
}

.add-accessReq-Key {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  margin: 0;
}

.add-accessReq-value {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  margin: 0;
}

.usertTypeRadioLabel {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 17px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #1c1c1c;
}

.access-mgmt-title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;

  /* Greyscale/100 */
  margin: 0px;
  color: #1c1c1c;
}

.access-preview-pane {
  height: calc(100vh - 228px);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #636799;
  // background: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #b4b5c8;
}

.access-preview {
  height: calc(100vh - 228px);
  background: #fff;
  border-radius: 4px;
  border: 2px solid #b4b5c8;
}

.displayModuleList {
  height: 200px;
  overflow-y: auto;
}

.moduleItem {
  border: 1px solid #c7caf5;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #737ae6;
  border-radius: 4px;
  padding: 8px 10px;
  margin: 0px;
  height: 32px;
}

.role-preview-details {
  background: #ffffff;
  height: calc(100vh - 365px);
  overflow-y: auto;
}
.user-preview-details {
  background: #ffffff;
  height: calc(100vh - 380px);
  overflow-y: auto;
}

.userDetailsHeader {
  margin: auto 0 auto auto;
  height: 125px;
}

.closeIcon {
  height: 12px;
  width: 12px;
  margin-top: 2px !important;
}

/* Role Classes */

.module-access-key {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #585757;
  float: left;
  text-align: left;
}

.module-access-value {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  float: left;
  text-align: left;
}

.access-description-preview {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  resize: none !important;
  border: 1px solid #e8e8f8;
  color: #1c1c1c;
  width: 100%;
  overflow-x: auto;
  padding: 8px;
  border-radius: 4px;
}

.avatarEdit {
  position: inherit;
  right: 6%;
  margin-top: 75px;
  top: 45px;
}
.avatarUpdate {
  position: inherit;
  margin-top: 75px;
  bottom: 40px;
  left: 25px;
}
.userEditIconDiv {
  background-color: #ccc;
  border-radius: 70px;
}

// .access-preview-pane {

//     height: calc(100vh - 248px);
//     background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #636799;
//     border-radius: 4px;
//     border:2px solid #B4B5C8;
//     }

option.select-placeholder {
  color: #d9d9d9;
}
.list-tabs {
  margin-left: -7rem;
}
.access-mgmt td.e-rowcell {
  text-transform: lowercase !important;
}
.access-mgmt-text-transform td.e-rowcell{
  text-transform: none !important;
}

.btnApprovedClass {
  text-transform: capitalize;
}
.btnDeniedClass {
  text-transform: capitalize;
}
