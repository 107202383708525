.panel.with-nav-tabs .panel-heading {
  padding: 0px 5px 0 5px;
}
.panel.with-nav-tabs .nav-tabs {
  border-bottom: none;
}

/*** PANEL Start ***/
.with-nav-tabs.panel-default .nav-tabs > li > a,
.with-nav-tabs.panel-default .nav-tabs > li > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li > a:focus {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;

  /* Greyscale/200 */

  color: #585757;
}
.with-nav-tabs.panel-default .nav-tabs > .open > a,
.with-nav-tabs.panel-default .nav-tabs > .open > a:hover,
.with-nav-tabs.panel-default .nav-tabs > .open > a:focus,
.with-nav-tabs.panel-default .nav-tabs > li > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li > a:focus {
  color: #777;
  background-color: #ddd;
  border-color: transparent;
}
.with-nav-tabs.panel-default .nav-tabs > li.active > a,
.with-nav-tabs.panel-default .nav-tabs > li.active > a:hover,
.with-nav-tabs.panel-default .nav-tabs > li.active > a:focus {
  color: #585757;
  background-color: #fff;
  border-color: #ddd;
  border-bottom-color: transparent;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
}
.with-nav-tabs.panel-default .nav-tabs > li.dropdown .dropdown-menu {
  background-color: #f5f5f5;
  border-color: #ddd;
}
.with-nav-tabs.panel-default .nav-tabs > li.dropdown .dropdown-menu > li > a {
  color: #777;
}
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > li
  > a:hover,
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > li
  > a:focus {
  background-color: #ddd;
}
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > .active
  > a,
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > .active
  > a:hover,
.with-nav-tabs.panel-default
  .nav-tabs
  > li.dropdown
  .dropdown-menu
  > .active
  > a:focus {
  color: #fff;
  background-color: #555;
}
ul.nav.nav-tabs li {
  width: 96px;
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  text-transform: none !important;
  font-size: 15px !important;
}

.totalTabDiv {
  width: 270px;
  height: 600px;
}
.panel-body {
  height: 380px;
}

.shortName {
  border: 1px solid #d5d5d5;
  font-family: Work Sans;
  text-align: center;
  color: #585757;
  border-radius: 50%;
  padding: 15%;
  background-color: #c1c2d6 !important;
  margin: 0px !important;
  font-size: 4rem;
  align-items: center;
}
.nameCard {
  height: 90px;
  padding-top: 20px;
  // background: linear-gradient(
  //     0deg,
  //     rgba(255, 255, 255, 0.9),
  //     rgba(255, 255, 255, 0.9)
  //   ),
  //   #636799;
  // border-top: 1px solid #cdcede;
  // border-left: 1px solid #cdcede;
  // border-right: 1px solid #cdcede;
  width: 100%;
  border-radius: 0px;
  background: #eff0f5;
}

.addressList {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.035em;

  /* Greyscale/200 */

  color: #585757;
}
.panel-default > .panel-heading {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #636799;
}
.name {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0015em;
  text-transform: capitalize;
}
.nameRow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
span.contactType {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.001em;
}
.storeNumHeading {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.035em;
  color: #585757;
  white-space: nowrap;
}
.storeNumHeading-hourly {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.035em;
  color: #585757;
}
.storeNumber {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #1c1c1c;
  text-transform: capitalize;
}
.txtEditClassName {
  font-family: Rubik !important;
  font-weight: normal !important;
  font-size: 13px !important;
  display: flex !important;
  max-width: fit-content;
  align-items: center !important;
  letter-spacing: 0.0025em !important;
  color: #1c1c1c !important;
  cursor: pointer !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.txtEditNotesClassName {
  width: 100%;
  display: block;
  font-family: Rubik !important;
  font-weight: normal !important;
  font-size: 13px !important;
  align-items: center !important;
  letter-spacing: 0.0025em !important;
  color: #1c1c1c !important;
  cursor: pointer !important;
}
.txtEditClassNameInput {
  border-color: #86b7fe;
  border-radius: 4px;
  box-shadow: 0px -1px 7px 2px #babefa;
  height: 30px;
  font-size: 13px !important;
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: Rubik !important;
  font-weight: normal !important;
  color: #1c1c1c !important;
}
.countryCodeDdl {
  font-size: 15px;
  color: #585757 !important;
}
.generalTabInfo {
  margin-bottom: 0.8rem;
}
/* toggle button style start*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #636799;
}
input:focus + .slider {
  box-shadow: 0 0 1px #636799;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}
.slider.round:before {
  border-radius: 50%;
}

.toggleSwitchInfo {
  margin-top: 5rem;
}
.toggleSwitch {
  padding-right: 0px;
}
.toggleStatus {
  padding-left: 0px;
}
/* toggle button style ends*/

/* warehouse starts*/
.generalTabWareHouseInfo {
  margin-bottom: 0.8rem;
}
.generalTabWareHouseData {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #737ae6;
  border-radius: 4px;
  margin-top: 8px;
  border: 1px solid #c5c6cb;
}
.linkedStoreHeading {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  letter-spacing: 0.035em;
  color: #585757;
}
.linkedStoreData {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #585757;
}
/* warehouse ends*/

.border-none {
  border: none !important;
}

.updateIconImg {
  cursor: pointer;
  height: 43px;
  padding-right: 4px;
}
.generalTabStoreDiv {
  height: 350px;
  overflow-y: auto;
  overflow-x: clip;
}
.height-350 {
  height: 350px;
}
.e-switch-wrapper .e-switch-on,
.e-css.e-switch-wrapper .e-switch-on .e-switch-handle {
  background-color: #4d841d !important;
  border-color: #4d841d !important;
}
.e-switch-wrapper .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
  background-color: #4d841d !important;
}
.e-switch-wrapper .e-switch-handle,
.e-css.e-switch-wrapper .e-switch-handle {
  background-color: #a19f9f !important;
}
.e-switch-wrapper .e-switch-off,
.e-css.e-switch-wrapper .e-switch-off .e-switch-handle {
  background-color: #a19f9f !important;
  border-color: #a19f9f !important;
}
.paddingGeneralTab {
  padding: 2rem;
  height: calc(100vh - 425px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }
  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}
.closeIconLinkedActions {
  margin-left: 3px;
}
.iconsForLinking {
  margin-left: 22px;
}
.loaderClass {
  height: 11rem;
}
.fontSize-14 {
  font-size: 14px;
}
.deptsInGLAccDiv {
  height: 175px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }
  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}
.glAccInDept {
  height: 210px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }
  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}
.linkedDeptInfoInGL {
  font-weight: 500;
  font-size: 14px;
}
.mr-3rem {
  margin-right: 3rem !important;
}
.switchTextTabs {
  font-size: 14px !important;
  color: #595959 !important;
  text-transform: uppercase !important;
}
.addHeadingInContactTab {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  letter-spacing: 0.035em;
  color: #585757;
  background: #eff0f5;
  padding: 3px;
  border: 1px solid #dedede;
}
.addreEditFields {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}
.custTypeAddedDiv {
  height: 118px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }
  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}
input.txtEditFieldFocus:focus {
  border: 1px solid #86b7fe !important;
  box-shadow: 0px 0px 5px 1px #86b7fe !important;
}
.horizontalLineBarCompanyCode {
  border: 1px solid #eee !important;
  margin-bottom: 0px !important;
  margin-top: 13rem !important;
}
.horizontalLineBar {
  border: 1px solid #eee !important;
  margin-bottom: 0px !important;
}
.horizontalLineBarCustomer {
  border: 1px solid #eee !important;
  margin-bottom: 15px !important;
}
.horizontalLineBarReportingMail {
  border: 1px solid #eee !important;
  margin-top: 55px !important;
}
.textAlign-right {
  text-align: right !important;
}
.notes-preview {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  resize: none !important;
  border: 1px solid #e8e8f8;
  color: #1c1c1c;
  width: 90%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }
  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}
.notesDiv {
  margin-top: 15rem;
}
.imgCanceIcon {
  height: 1.2rem;
  margin: 5px 5px;
}
.imgCanceIconBin {
  margin-bottom: 7px;
}
.glAccountNumSpan {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
textarea.notes-preview:focus {
  border: 1px solid #86b7fe !important;
  box-shadow: 0px 0px 5px 1px #86b7fe !important;
}
.notesHead {
  margin-top: 5px;
}
select.ddlFocus:focus {
  border: 1px solid #86b7fe !important;
  box-shadow: 0px 0px 5px 1px #86b7fe !important;
}
.boxShadow-None {
  box-shadow: none;
}
.reportHeading {
  background: #eaeaea;
  font-weight: 500;
  font-size: 14px;
  color: #535353;
}
.reportHeadingText {
  padding-top: 3px;
}
.whLocInBinExcep {
  height: 140px;
  overflow: auto;
  padding: 0 4px 0 0;
  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }
  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}
.textOverlap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
}
.lblemail {
  margin: 9px 1px 10px 0px;
}
.lblFax {
  margin: 9px 1px 10px 0px;
}
.lblphone {
  margin: 9px 1px 10px 0px;
}
.lbllanguage {
  margin: 7px 0px 8px 0px;
}
.lblCurrency {
  margin: 7px 0px 8px 0px;
}
.lbladdress {
  margin: 9px 0px 8px 0px;
}
.lblaltphone {
  font-size: 14px;
  margin: 9px 1px 10px 0px;
}
.lblName {
  margin: 5px 0px 3px 0px;
}
.lblVendorName {
  margin: 2px 0px 0px 0px;
}
.lblStoreName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0px 3px 0px;
}
.lblSupergrp {
  margin: 7px 0px 0px 0px;
}
.lblReasoncode {
  margin: 7px 0px 0px 0px;
}
.txtEditFieldFocus {
  color: #585757 !important;
}
.altEmailAddImgStore {
  margin-left: 0.5rem;
}
.newAltEmailLabel {
  margin-top: 0.7rem;
  font-size: 14px !important;
}
.upcDispoHeadingSD {
  margin-top: 0.7rem;
}
.upcDispoHeading {
  margin-top: 0.9rem;
}

.addCloseIcon {
  width: 12px;
  margin: 5px 5px 0 0;
}
