@import "../../styles/global.scss";

.contact-screen {
  @include H5;
  // padding-top: 25px;
  width: 100%;
  // margin-left: 50px;
  .tab-wrapper {
    border-bottom: 1px solid #e0e0e0;
    // margin-bottom: 30px;
  }

  .contact-name {
    text-align: start;
  }

  .contacts-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  // .e-grid {
  //     width: 100% !important;
  // }

  .print-pallet-label {
    padding: 0 !important;
    box-sizing: border-box;
    background: #FCFCFC;
    border: 1px solid #636799;
    border-radius: 4px;

    span {
      letter-spacing: 0.0125em;
      text-transform: uppercase;
      color: #636799;
    }
  }
}

.e-toolbar-items {
  float: left;
}

.detailsTabEmpty {
  height: calc(100vh - 226px);
  border: 1px solid #e8e8e8;
  width: 25%;
  border-radius: 6px;
}

.detailsTab {
  height: calc(100vh - 226px);
  border: 2px solid #b4b5c8;
  width: 25%;
  border-radius: 4px;
}

.selectTextForEmpty {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #1c1c1c;
  margin: 0;
}

/*Add forms styles*/
.lblClass {
  font-weight: 600 !important;
  font-size: 13px;
  float: right;
}

.lblClassAppliesTUPC {
  font-size: 10px;
  line-height: 0;
  float: right;
}

.addPlusIcon {
  //background: #636799;
  cursor: pointer;
}

.textTransform-initial {
  text-transform: initial !important;
  box-shadow: none !important;
  background-color: transparent !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 18px !important;
  align-items: center;
  text-align: center !important;
  letter-spacing: 0.035em;
  color: #585757 !important;
}

.linkedStoreDiv {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #737ae6;
  margin-top: 5px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #c5c6cb;
}

.linkedStoreNameRemove {
  font-weight: 900;
  float: right;
  cursor: pointer;
}

.textAlign-right {
  text-align: right;
}

.textAlign-center {
  text-align: center;
}

.readOnlyInput {
  background-color: #fff !important;
}

.masterDataGrid {
  width: 73%;
}

.backToAllText {
  font-size: 1.5rem;
  cursor: pointer;
}

.lblRadioText {
  font-size: 16px;
  margin-right: 2rem;
}

.RCListInRuleExceptionAdd {
  height: 120px;
  background: #f2f2f2;
  padding: 2px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }

  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}

.RCSummaryDiv {
  background: #f2f2f2;
  padding-bottom: 2rem;
}

.RCSummaryText {
  background: #989898;
  padding: 5px;
  font-size: 14px;
}

.RCSummaryInsideHeadings {
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
}

.p-0 {
  padding: 0px;
}

.inventoryCatalog-table {
  width: 80%;
  max-width: 750px;
  margin: 1rem;
  margin: 0 auto;
  border-radius: 4px;
  font-weight: 500;
  border: 2px solid #dedede;
  overflow: hidden;
  font-family: 'Rubik';

  .inventoryCatalog-table-header {
    background-color: #e0e1eb;
    display: flex;
    padding: 8px;
    justify-content: space-between;
    width: 100%;
    font-size: 15px;
    text-align: center;

    & > span {
      width: 16%;
    }
  }

  .inventoryCatalog-table-content {
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    font-size: 15px;
    text-align: center;

    & > span {
      width: 16%;
    }
  }
}

.inventoryBinsandBoxesHeading {
  border-bottom: 1px solid #c8c9d1;
  padding-bottom: 5px;
  padding-left: 0px;
}

.lblBarCodeInvent {
  font-size: 17px;
}

.statusFlagActiveItemCatlog {
  font-size: 18px;
  background: #c9e7b9;
  padding: 10px;
  border-radius: 5px;
}

.statusFlagHoldItemCatlog {
  font-size: 18px;
  background: linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 0.6),
                  rgba(255, 255, 255, 0.6)
  ),
  #8c4d3f;
  padding: 10px;
  border-radius: 5px;
}

.activeRuleExcepItemCatalog {
  font-size: 14px;
  color: #898fea;
  font-weight: 600;
  text-decoration: underline;
}

.holdFlagTextItemCatlog {
  font-size: 15px;
}

.txtPhone {
  background-image: url(../../assets/USFlag.png) !important;
  background-size: 35px !important;
  background-repeat: no-repeat !important;
  padding-left: 4rem !important;
}

.notInvoicedFlagInventory {
  font-size: 15px;
  background: #d1b8b2;
  padding: 10px;
  border-radius: 5px;
}

.disableRadioBtn {
  //pointer-events: none;
  height: 15px;
  width: 15px;
}

.enableRadioBtn {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.templateHeaderNameFiscal {
  font-size: 17px;
  font-weight: 600;
}

.templateNameFiscal {
  font-size: 18px;
  margin-left: 8px;
  margin-right: 8px;
  font-weight: 600;
}

.templateDescFiscal {
  font-size: 18px;
}

span.templatename {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  float: left;
  margin-top: 6.6%;
}

.templateContentFiscalYear {
  margin-left: 1rem;
}

.fiscalYearTempStepsHeading {
  font-size: 19px;
}

.goBackBtnFiscalYear {
  color: #636799;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}

.UPCandWHlocationHeading {
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
}

.requiredStar {
  color: red;
  padding-left: 0px;
}

.requiredText {
  color: red;
}

.authList {
  background: #e8e8e8;
}

.authFlagDiv {
  background: #f2f2f2;
}

.activeTab {
  border-bottom: 2px solid #7f86e8 !important;
  color: #737ae6 !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.035em !important;
  pointer-events: none;
}

.altAddreesDiv {
  height: 150px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }

  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}

.pr-0 {
  padding-right: 0px !important;
}

.custTypeDivAdd {
  height: 100px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }

  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}

.linkedRCDiv {
  height: 150px;
  overflow: auto;
}

.linkedGLAccAddInDeptDiv {
  height: 150px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }

  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}

.linkedDeptAccAddInGLAccDiv {
  height: 150px;
  overflow: auto;
}

.displyNone {
  display: none;
}

.lblRate {
  margin-top: 8px;
}

.fiscalYearNoData {
  border: 1px solid #cecfde;
  text-align: center;
  padding: 21rem;
  background: #eff0f5;
  border-radius: 5px;
}

.emptyDivText {
  font-size: 1.6rem;
  font-weight: 600;
  color: #59595a;
}

.image-wrapper {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05) !important;
}

.lblRatePalletView {
  margin-top: 7px;
}

.upcDefAddImg {
  position: relative;
  bottom: 4rem;
}

// .btnPalletUpdate {
//   // position: relative;
//   // bottom: 4.5rem;
//   // margin-left: -3rem;
//     float: right;
//     padding-right: 93px;
// }

.palletLocationSaveIcon {
  position: relative;
  right: 93px;
  bottom: 45px;
}

.palletRatelbl {
  margin-top: 5px;
}

.fontSize-11 {
  font-size: 11px;
}

.horizontalLineBarInPalletView {
  border: 0.1px solid #eee !important;
  margin-bottom: 0px !important;
  margin-top: -1rem !important;
}

.selectdiv {
  // position: relative;
  // float: left;
  // min-width: 200px;
  // margin: 50px 33%;
}

select::-ms-expand {
  display: none;
}

.selectdiv:after {
  content: "<>";
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  // right: 11px;
  // top: 18px;
  padding: 0 0 2px;
  border-bottom: 1px solid #999;
  position: absolute;
  pointer-events: none;
}

.updateIconRuleExce {
  // position: relative;
  // bottom: 6.5rem;
  // margin-left: -4.5rem;
  position: relative;
    float: right;
    bottom: 32px;
    right: 47px;
}

.billCostByUpcDiv {
  height: 150px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }

  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}

// .saveBtn {
//   bottom: 4.45rem;
//   margin-left: 7.5rem;
// }

// .saveBtnItem {
//   // bottom: 6.45rem;
//   // margin-left: 7.5rem;
//   bottom: 6.5rem;
//   float: right;
//   padding-right: 131px;
// }

#ddlVendorList_popup div {
  max-height: 500px !important;
 z-index: 7777 !important;
 top:0px !important;
  left:0px !important;
}
.ItemCataLogBtn {
  position: relative;
  bottom: 64px;
  right: 78px;
}
.inventory-catalog-btn {
  position: relative;
  bottom: 62px;
  right: 230px;
}
.inventory-catalog-btn img {
  width: 37px;
  height: 38px;
}
.catlogItemBtn {
  margin-left: 3rem;
}

.hazardousHeading {
  margin-bottom: 2%;
}

.activerule {
  font-size: 13px;
  font-weight: 600;
}

.lastImportDate {
  margin-top: 0.7rem;
}

.save-btn {
  bottom: 4rem;
}

.default-view {
  margin-top: -0.8%;
}

.location-enabled {
  margin-left: 5rem;
  font-weight: 500 !important;
}

.btnUpdateBinExcep {
  position: relative;
  bottom: 65px;
  right: 94px;
}

.noactviverule-btn {
  background: #d1b8b2;
  font-size: 16px;
  padding: 0.3rem 1.5rem 0.2rem 1.5rem;
  border-radius: 0.5rem;
}

.noactviverule-btn {
  background: #d1b8b2;
  font-size: 16px;
  padding: 0.3rem 1.5rem 0.2rem 1.5rem;
  border-radius: 0.5rem;
}

.yesactviverule-btn {
  background: #c9e7b9;
  font-size: 16px;
  padding: 0.3rem 1.5rem 0.2rem 1.5rem;
  border-radius: 0.5rem;
}

.mainheading-border {
  margin-top: -1rem;
}

.lblClass-time {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  margin-top: 5%;
}

.time {
  margin-top: -3rem;
}

.poptime {
  margin-top: -1rem;
}

.binpop-content {
  margin-right: 2.5rem;
}

.customerNum {
  margin-right: -2rem;
}

.accountNUm {
  margin-right: -1rem;
}

.billingmode {
  margin-right: -2rem;
}

.defaultAdd {
  margin-right: 1rem;
}

.addDepart-popup {
  margin-right: 4rem;
}

.GLadd_popup {
  margin-right: 3.6rem;
}

.FY-pop-content {
  margin-top: -1.3rem;
}

.commodity-popup {
  margin-right: 4rem;
}

.addwhsebtn {
  margin-right: 5rem;
}

.palletpop {
  margin-right: 3.8rem;
  margin-top: 5rem;
}

.Hazardous-border {
  margin-top: 0rem;
}

///////////////////anvesh tabs css///////////@at-root

.contact-screen .tab-wrapper {
  margin-left: 0.4rem !important;
}

.textTransform-initial {
  padding-left: 0rem !important;
  padding-right: 0.7rem !important;
  margin-right: 1rem !important;
}

.ll-custom-tab.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
  border-bottom: 0px solid #737ae6 !important;
}

///////////////////tabs css end/////////
option.select-placeholder {
  color: #d9d9d9 !important;
}

.contactcloseIcon {
  height: 9px;
  width: 8px;
  margin: 6px 6px !important;
}

.selectedRow {
  background: linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 0.8),
                  rgba(255, 255, 255, 0.8)
  ),
  #737ae6;
  border-radius: 4px;
}

.selectedtem {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  font-size: 12px !important;
  text-decoration: underline !important;
  font-weight: 600 !important;
  cursor: pointer;
  // border-bottom: 1px solid #000;
}

//RajaRavanam Masterdata Image Tabs
img.imgOrg,
.imgPart,
.imgFinance,
.imgIt,
.imgInventory {
  width: 100%;
  height: 60%;
}
.RCListInRuleExceptionView {
  height: 235px;
  background: #f2f2f2;
  padding: 2px;
  overflow-y: scroll;
  margin-left: 1rem;

  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }

  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}


.lblClassRule {
    font-family: Rubik !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px;
    letter-spacing: 0.035em;
    color: #1c1c1c;
    float: left;
}
.upcBillcostList{
  height: 200px;
  overflow-y: scroll;
}

button.e-control.e-btn.e-lib.eg-btn-primary.float-right.pt-3.pb-3.pl-5.pr-5 {
  width: 168px;
  height: 48px;
}

//CssForAsyncSelect RR
.css-1s2u09g-control {
    height: 34px !important;
    border: 1px solid #969696 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    font-family: Rubik !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.02em;
    color: #1c1c1c;
    min-height: 34px !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.css-tlfecz-indicatorContainer {
  padding: 2px !important;
}

svg.css-tj5bde-Svg {
  color: black !important;
  width: 15px !important;
  height: 15px !important;
}

.css-1pahdxg-control:hover {
  box-shadow: none !important;
  border-color: #969696 !important;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: #969696 !important;
  min-height: 34px !important;
  height: 34px !important;
}

.col-sm-12.p-0.upcDropDownBox span.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard {
  background: #ffffff !important;
    border: 1px solid #969696 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    font-family: Rubik !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.02em;
    color: #1c1c1c;
    padding-left: 10px;
    padding-right: 2px;
}
.col-sm-12.p-0.upcDropDownBox span.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard:active {
  box-shadow: none;
}
.col-sm-12.p-0.upcDropDownBox span.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
  content: '\e776';
  font-family: 'e-icons';
  color: black;
  font-weight: 600;
  font-size: 12px;
  transform: rotate(180deg);
}