@import "../../../../styles/global.scss";

.miscSalvage-form {
  display: flex;
  justify-content: space-between;
  .miscSalvage-form-column {
    width: 50%;
    padding: 1rem;
    .miscSalvage-form-field {
      width: 100%;
      display: flex;
      margin: 1rem 0;
      @include InputStyle();
    }
    .des-txt {
      display: flex;
      margin: 1rem 0;
      margin-left: 15rem;
      margin-right: -8rem;
      @include InputStyle();
      margin-left: 12rem;
    }

    .miscSalvage-radio {
      width: 100%;
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .miscSalvage-form-radiobuttons {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;
        .miscSalvage-form-radiobuttons-withinput {
          flex-direction: row !important;
          .radiobuttons-input {
            @include InputStyle();
          }
        }
      }
    }
  }
}
.miscSalvageItem-form-buttons {
  display: flex;
  justify-content: end;
}
.weightdiv {
  margin-right: 16rem;
}
