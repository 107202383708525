// @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
//@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-layouts/styles/material.css";
//@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

.App {
  text-align: center;
  box-sizing: border-box;
}
div#reportviewer-container_viewerContainer {
  height: 700px;
  width: 100%;
  font-size: 8pt;
}
/*Overwritten styles after bold reports integration* starts*/
.e-icon.e-calendar:before {
  width: 100% !important;
}
.e-widget.e-datepicker .e-active, .e-widget.e-datepicker .e-active:hover, .e-widget.e-datepicker .e-active.e-state-hover {
  color: #ffffff;
  right: 10px;
  text-align: center !important;
  border-radius: 50% !important;
}
.e-widget.e-datepicker table td::before {
  width: 32px !important;
  height: 37px !important;
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: -1;
}
.e-widget.e-ntouch.e-datepicker table td:hover, .e-widget.e-datepicker td.e-state-hover, .e-widget.e-ntouch.e-datepicker .current-month.e-state-default.e-special-day:hover {
 background: none !important;
 border-color: transparent;
 box-shadow: none;
}
.e-widget.e-ntouch.e-datepicker table td:hover::before, .e-widget.e-ntouch.e-datepicker .current-month.e-state-default.e-special-day:hover::before{
  background: none !important;
}
.e-widget.e-datepicker .e-header .e-text {
  width: 65%;
}
.e-widget.e-scrollbar .e-vscroll {
  display: none;
}
.e-widget.e-scrollbar {
  display: none !important;
}
.e-widget.e-scroller, .e-widget.e-scroller>.e-content {
  overflow: auto !important;
}
.e-input-group-icon.e-date-icon::before, .e-lib.e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e901' !important;
  font-family: 'e-icons';
}
.e-input-group-icon.e-range-icon::before, .e-lib.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: '\e245' !important;
  font-family: 'e-icons';
}
/*Overwritten styles after bold reports integration* ends*/
.body-route {
  height: 500px !important;
  overflow-y: auto !important;
}

@font-face {
  font-family: Rubik;
  src: url(../src/fonts/Rubik-VariableFont_wght.ttf);
}
