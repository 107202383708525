#multichecbox .property-panel-content {
  overflow: initial;
}
#multichecbox .col-lg-9 {
  min-height: 250px;
}
#multichecbox #multigroup {
  margin: 0 auto;
  width: 300px !important;
  padding-top: 15px;
}

#multiselection .property-panel-content {
  width: 300px;
}
#multichecbox .property-section {
  left: 0px;
}
