.reports-preview-pane {
    height: calc(100vh - 247px);
    background: #ffffff;
    border-radius: 4px;
    border: 2px solid #b4b5c8;
}

.reports-header-text {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #1c1c1c;
}

.reports-sub-header-text {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.001em;

    /* Greyscale/200 */

    color: #585757;
}

.reports-preview-card {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
        ),
        #636799 !important;
    border-radius: 4px;
    height: calc(100vh - 252px);
    // overflow-y: auto;
    border: 1px solid #636799;
}

.reports-keys {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    // display: flex;
    // align-items: center;
    text-align: left;
    letter-spacing: 0.035em;

    /* Greyscale/200 */

    color: #585757;
}

.reports-values {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    /* Greyscale/100 */

    color: #1c1c1c;
}
