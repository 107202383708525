.user-main-header-text {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.0015em;
  color: #1c1c1c;
  margin: 0px !important;
  margin: 0px !important;
  float: left;
  text-align: left;
  text-transform: capitalize;
}

.preview-card {
  background: #eff0f5 !important;
  border-radius: 4px;
  height: calc(100vh - 299px);
  // overflow-y: auto;
  border: 2px solid #b4b5c8;
}

.preview-details {
  background: #ffffff;
  height: calc(100vh - 432px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 8px !important;
    width: 10px !important;
  }
  &::-webkit-scrollbar-track {
    height: 5px !important;
    background: #d3d3d345 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    height: 5px !important;
    background: #8080806e !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #636799 !important;
  }
}

.preview-Key {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #585757;
  float: left;
  text-align: left;
  margin: 0px !important;
}

.preview-value {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #1c1c1c;
  margin: 0px !important;
  float: left;
  text-align: left;
}

.header-logo {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 50px;
  text-align: center;
  color: #585757;
  border-radius: 50%;
  padding: 15%;
  background-color: #c1c2d6 !important;
  margin: 0px !important;
}
.header-profile-logo {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 80px;
  text-align: center;
  color: #585757;
  border-radius: 50%;
  padding: 15%;
  background-color: #c1c2d6 !important;
  margin: 0px !important;
}

.main-header-text {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.0015em;
  color: #1c1c1c;
  margin: 0px !important;
  margin: 0px !important;
  float: left;
  text-align: left;
  text-transform: capitalize;
}

.sub-header-text {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.001em;
  color: #585757;
  margin: 0px !important;
  float: left;
  text-align: left;
}

.preview-status {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.035em;

  /* Greyscale/200 */

  color: #585757;
  margin: 0;
}
.text-transform-normal{
  text-transform: none !important;
}