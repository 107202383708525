@import "./variables";
@import "./colors";
@import "./buttons.scss";
@import "./typography";
@import "./wrapper.scss";
@import "./customSync.scss";
@import "./fonts.scss";
@import "./customBootStrap.scss";

#header ul {
  background-color: white;
  color: black;
  overflow: hidden;
  // height:56px;
}
// th.e-headercell {
//   background: $eg-primary-dark !important;
//   color: #fff;
// }
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-1rem {
  margin-bottom: 1rem !important;
}

.mt-10 {
  margin-top: 10px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.p-15 {
  padding: 15px;
}
.p-10 {
  padding: 10px !important;
}
.pl-0 {
  padding-left: 0px;
}
.pl-3rem {
  padding-left: 3rem !important;
}
.ml-1rem {
  margin-left: 1rem;
}
.mt-3rem {
  margin-top: 3rem;
}
.ml-2rem {
  margin-left: 2rem;
}
.ml-6rem {
  margin-left: 6rem;
}
.mt-1rem {
  margin-top: 1rem;
}
.mt-2rem {
  margin-top: 2rem;
}
.mt-6rem {
  margin-top: 6rem;
}
.mr-6rem {
  margin-right: 6rem;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left;
}
.float-left {
  float: left !important;
}
.no-padding {
  padding: 0px !important;
}
.e-menu-item {
  .sidemenu-icons {
    width: 24px !important;
    height: 24px !important;
    background-position: center center !important;
    display: inline-block !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    vertical-align: middle !important;
    &.cs-home-icon {
      background-image: url("../icons/home.png") !important;
    }
    &.cs-scan-icon {
      background-image: url("../icons/scan.png") !important;
    }
    &.cs-admin-icon {
      background-image: url("../icons/admin.png") !important;
    }
    &.cs-invoice-icon {
      background-image: url("../icons/invoice.png") !important;
    }
    &.cs-reports-icon {
      background-image: url("../icons/report.png") !important;
    }
    &.cs-settings-icon {
      background-image: url("../icons/settings.png") !important;
    }
  }
}
.e-dropdown-popup {
  box-sizing: border-box;
  padding: 4px !important;
  background-color: white !important;
  box-shadow: 2px 2px 10px #ccc !important ;

  & > ul {
    box-shadow: none !important ;
  }
}
.e-menu-item {
  &.e-focused {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
      ),
      linear-gradient(0deg, #79c24f, #79c24f) !important;
  }
  .sidemenu-icons {
    width: 24px !important;
    height: 24px !important;
    background-position: center center !important;
    display: inline-block !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    vertical-align: middle !important;
    &.cs-home-icon {
      background-image: url("../icons/home.png") !important;
    }
    &.cs-scan-icon {
      background-image: url("../icons/scan.png") !important;
    }
    &.cs-admin-icon {
      background-image: url("../icons/admin.png") !important;
    }
    &.cs-invoice-icon {
      background-image: url("../icons/invoice.png") !important;
    }
    &.cs-reports-icon {
      background-image: url("../icons/report.png") !important;
    }
    &.cs-settings-icon {
      background-image: url("../icons/settings.png") !important;
    }
  }
}

.cs-logo {
  float: left;
}
.current-day {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(0deg, #636799, #636799) !important;
  list-style-type: none;
  width: 300px;
  display: contents;
  line-height: 46px;
}
.read-only-span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  color: #898989;
  font-size: 13px;
  &:hover {
    cursor: not-allowed;
  }
}
@mixin InputStyle {
  p {
    // text-align: end;
    margin-top: 0.625rem !important;
    padding-right: 10px;
    width: 35% !important;
    font-weight: bold;
    text-align: right;
  }
  span:first-of-type {
    width: 65% !important;
    border: 1px solid gray;
    border-radius: 4px !important;
    .select-control + span {
      border: none !important;
      width: 40px !important;
    }
    .settings-tab-title {
      width: 75% !important;
      background: #ffffff !important;
      border: 1px solid #969696 !important;
      box-sizing: border-box;
      border-radius: 6px !important;
    }
  }
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
  .settings-tab-title {
    width: 75% !important;
    background: #ffffff !important;
    border: 1px solid #969696 !important;
    box-sizing: border-box;
    border-radius: 6px !important;
  }
}
.fontWeight-Bold {
  font-weight: bold !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px !important;
  width: 10px !important;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  height: 5px !important;
  background: #8080806e !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-track {
  height: 5px !important;
  background: #d3d3d345 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #636799 !important;
}

input.txtEditFieldFocus:focus {
  border: 1px solid #86b7fe !important;
  box-shadow: 0px 0px 5px 1px #86b7fe !important;
}

.boxShadow-None {
  box-shadow: none;
}

.border-none {
  border: none !important;
}

.textOverlap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
}

.previewScreenDevider {
  color: #cccccc !important;
  border-top: 1px solid #cccccc !important;
}
.e-pagesizes {
  float: left !important;
}
///////////////////anvesh tabs css///////////@at-root
.tab-control-section .e-tab-wrap {
  margin-right: 2rem;
  // border-bottom: 1px solid red !important;
  padding: 0px 0rem 0px 0px !important;
  border-bottom: 0px solid white !important;
}
.access-preview-pane .e-tab-wrap {
  margin-right: 0rem;
  padding: 0.3rem 1rem 1rem 1rem !important;
  border-bottom: 0px solid white !important;
}
.report-preview-card .e-tab-wrap {
  margin-right: 0rem;
  padding: 0.3rem 1rem 1rem 1rem !important;
  border-bottom: 0px solid white !important;
}
.detailsTab .e-tab-wrap {
  margin-right: 0rem;
  padding: 0.3rem 1rem 1rem 1rem !important;
  border-bottom: 0px solid white !important;
}
.child-data-section .e-tab-wrap {
  margin-right: 0rem;
  padding: 0.3rem 1rem 1rem 1rem !important;
  border-bottom: 0px solid white !important;
}
.access-preview-pane
  .e-tab
  .e-tab-header
  .e-toolbar-item.e-active
  .e-text-wrap {
  border-bottom: 0px solid white !important;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  border-bottom: 0px solid white !important;
}
.ll-custom-tab.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
  border-bottom: 0px solid #737ae6 !important;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  border-bottom: 3px solid #737ae6 !important;
}
.contact-screen .tab-wrapper {
  margin-left: 0rem !important;
}
.detailsTab .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  border-bottom: 0px solid white !important;
}
.report-preview-card
  .e-tab
  .e-tab-header
  .e-toolbar-item.e-active
  .e-text-wrap {
  border-bottom: 0px solid white !important;
}
.textTransform-initial {
  padding-left: 0rem !important;
  padding-right: 0.7rem !important;
  margin-right: 1rem !important;
}
.e-tab-header {
  margin-left: 0rem;
}
///////////////////tabs css end/////////
p.child-tab-title {
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.settings-tab-title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  margin: 0px;
  color: #1c1c1c;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  margin: 0px;
  color: #1c1c1c;
}

//Toggle button styles
input[type="checkbox"]#mobileTracking + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#mobileTracking + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#mobileTracking:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#mobileTracking:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#mobileTracking:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.mobileTracking {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

//mobileScanEnabled button styles
input[type="checkbox"]#mobileScanEnabled + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#mobileScanEnabled + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#mobileScanEnabled:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#mobileScanEnabled:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#mobileScanEnabled:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.mobileScanEnabled {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

//salvager button styles
input[type="checkbox"]#salvager + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#salvager + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#salvager:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#salvager:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#salvager:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.salvager {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

//underReview button styles
input[type="checkbox"]#underReview + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#underReview + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#underReview:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#underReview:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#underReview:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.underReview {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -9000px;
  top: -9000px;
}

input[type="checkbox"].toggle + .chkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-left: 35px;
  margin-top: 3px;
}

input[type="checkbox"].toggle + .chkbx {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-top: 2px;
}

//generalTabActive button styles
input[type="checkbox"]#generalTabActive + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#generalTabActive + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#generalTabActive:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#generalTabActive:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#generalTabActive:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.generalTabActive {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -9000px;
  top: -9000px;
}

input[type="checkbox"].toggle + .chkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-left: 35px;
  margin-top: 3px;
}

input[type="checkbox"].toggle + .chkbx {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-top: 2px;
}

//NotesTabActive button styles
input[type="checkbox"]#NotesTabActive + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#NotesTabActive + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#NotesTabActive:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#NotesTabActive:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#NotesTabActive:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.NotesTabActive {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -9000px;
  top: -9000px;
}

input[type="checkbox"].toggle + .chkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-left: 35px;
  margin-top: 3px;
}

input[type="checkbox"].toggle + .chkbx {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-top: 2px;
}

//ContactTabActive button styles
input[type="checkbox"]#ContactTabActive + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#ContactTabActive + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#ContactTabActive:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#ContactTabActive:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#ContactTabActive:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.ContactTabActive {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -9000px;
  top: -9000px;
}

input[type="checkbox"].toggle + .chkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-left: 35px;
  margin-top: 3px;
}

input[type="checkbox"].toggle + .chkbx {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-top: 2px;
}

//HoldToggle button styles
input[type="checkbox"]#HoldToggle + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#HoldToggle + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#HoldToggle:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#HoldToggle:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#HoldToggle:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.HoldToggle {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -9000px;
  top: -9000px;
}

input[type="checkbox"].toggle + .chkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-left: 35px;
  margin-top: 3px;
}

input[type="checkbox"].toggle + .chkbx {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-top: 2px;
}

//PrepaidToggle button styles
input[type="checkbox"]#PrepaidToggle + label::before {
  content: "";
  width: 3.6em;
  height: 1em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;

  border-radius: 1em;
  margin-right: 0.5em;
  transition: background-color 200ms ease-in-out;
}

input[type="checkbox"]#PrepaidToggle + label::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "\2715";
  font-size: 0.899em;
  height: 1em;
  left: 0em;
  width: 2.2em;
  height: 1.8em;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #8c4d3f;
  color: white;
  border-radius: 50%;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type="checkbox"]#PrepaidToggle:focus + label::before {
  //outline: 1px solid black;
}

input[type="checkbox"]#PrepaidToggle:checked + label::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

input[type="checkbox"]#PrepaidToggle:checked + label::after {
  content: "\2713";
  transform: translateX(100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
}

.PrepaidToggle {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(0deg, #79c24f, #79c24f);
  // background-color: #79c24f;
}

input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -9000px;
  top: -9000px;
}

input[type="checkbox"].toggle + .chkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-left: 35px;
  margin-top: 3px;
}

input[type="checkbox"].toggle + .chkbx {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035em;
  text-align: center;
  margin-top: 2px;
}
// .e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame {
//   background-color: transparent !important;
//     border: 2px solid #ffffff !important;
//     border-color: #ffffff !important;
//     box-sizing: border-box;
//     border-radius: 1px !important;
// }

.e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: transparent !important;
  border: 2px solid #757575 !important;
  border-radius: 1px !important;
  color: #fff;
}
.rate-class {
  font-size: 10px !important;
  padding-left: 1px;
}
//Loader CSS

.loaderClassSaved {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #636799;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  .container4 {
    height: 20em;
    position: relative }
.container4 .loaderClassSaved {
    position: absolute;
    top: 50%;
    left: 42%;
}

// .e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame {
//   background-color: #636799 !important;
//   border-color: #ffffff;
// }

.e-frame, .e-css.e-checkbox-wrapper:hover .e-frame {
  background-color: #636799;
  border-color: #ffffff;
}
select#ddlCountry option:first-child{
	display: none;
}
select#ddlLaguage option:first-child{
	display: none;
}
select#ddlDisposition  option:first-child{
	display: none;
}
select#ddlRole  option:first-child, select#ddlScanGroup option:first-child{
  display: none !important;
}
//Raja ImageEditIcon

.middle {
 transition: .1s ease;
 opacity: 0;
 position: absolute;
 top: 66%;
 left: 79%;
 // transform: translate(-50%, -50%);
 // -ms-transform: translate(-50%, -50%);
 text-align: center;
}
.HoverEditIcon {
 transition: .1s ease;
 opacity: 0;
 position: absolute;
 left: 69%;
 bottom: 14%;
}

.UserImageHover:hover .HoverEditIcon {
  opacity: 1;
 background-color: #cccccc;
 border-radius: 50%;
}

.UserImageHover:hover .userPreviewIcon {
  background: #aea9a9 !important;
}

.HoverEditIconUsers {
 transition: .1s ease;
 opacity: 0;
 position: absolute;
 left: 53%;
 top: 63%;
}

.AddUsersImageHover:hover .HoverEditIconUsers {
  opacity: 1;
 background-color: #cccccc;
 border-radius: 50%;
}

.AddUsersImageHover:hover .userAddIcon {
  background: #aea9a9;
 }

 .RoleHoverEditIcon {
  transition: .1s ease;
  opacity: 0;
  position: absolute;
  left: 53%;
  top: 63%;
 }

 .RoleImageHover:hover .RoleHoverEditIcon {
  opacity: 1;
 background-color: #cccccc;
 border-radius: 50%;
}

.RoleImageHover:hover .userAddIcon {
  background: #aea9a9;
 }

.roundImage:hover .middle {
 opacity: 1;
 background-color: #cccccc;
 border-radius: 50%;
}

.roundImage:hover .userAddIcon {
 background: #aea9a9;
}

//Raja Grid Checkboxes Alignment
.e-grid .e-rowcell .e-checkbox-wrapper, .e-grid .e-rowcell .e-css.e-checkbox-wrapper {
  float: left;
}

.LoadImg {
  position: fixed;
  bottom: 40%;
  left: 39%;
  width: 315px;
}
td {
  text-align: left !important;
}
.access-mgmt td {
  text-transform: none !important;
}

span.btnActiveClass {
  text-transform: capitalize;
}

span.btnInActiveClass {
  text-transform: capitalize;
}

div#FGrid\ StoreGrid td.e-rowcell:nth-last-child(2) {
  text-align: center !important;
}

img.contactcloseIcon.float-right {
  cursor: pointer;
}

.e-grid .e-gridheader .e-rightalign .e-sortfilterdiv {
  float: right;
  margin: -10px 4px;
  padding: 3px;
  width: 10px;
}

span.e-frame.e-icons.e-uncheck {
  background-color: #636799 !important;
  border-color: #ffffff;
}

span.e-remain {
  padding-left: 0px !important;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame, 
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame {
  background-color: #636799 !important;
  border-color: #ffffff !important;
}