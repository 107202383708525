.parentDiv {
  overflow: auto;
}
.settings-tab-title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  margin-left: -1rem;
  // margin:5% 0;
}
.fileIcoClass {
  width: 30px;
  height: 30px;
}
.settingsTitleRow {
  margin: 2% 0 0 0;
  border-bottom: 1px solid #cccccc;
}
.settingsSubTitleRow {
  background-color: #f2f2f2;
  margin-top: 0;
  border-radius: 8px;
}
.settings-select-control {
  background: #ffffff;
  border: 1px solid #969696;
  box-sizing: border-box;
  border-radius: 6px;
}
.opt-btn-dark {
  margin-bottom: 5px !important;
}

.genSettingInputLabel {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.035em;
  color: #1c1c1c;
  text-align: end !important;
  padding: 5px 10px;
}
.genSetInput {
  border: 1px solid #969696 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  padding: 4px !important;
}
.settings-tab-sub-div {
  margin: 3% 0 1% 0 !important;
}
.genSetSubInput {
  border: 1px solid #969696 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  padding: 4px !important;
  width: 100%;
}
.settings-tab-sub-row {
  margin-bottom: 2%;
}
.settings-tab-sub-div-sub {
  margin: 1% 0;
}

.closeIconMain {
  width: 21px;
  padding: 0px;
  float: left;
  margin-top: 27px !important;
}
.closeIconSub {
  width: 21px;
  padding: 0px;
  float: left;
  margin-top: 10px !important;
}
.closeIconScan {
  width: 20px;
  padding: 0px;
  float: left;
  margin-top: 5% !important;
}
.closeIconScanSub {
  width: 20px;
  padding: 0px;
  float: left;
  margin-top: 3% !important;
}
.closeIcon {
  width: 20px;
  padding: 0px;
  float: left;
  margin-top: 1% !important;
}

.settings-tab-sub-div {
  margin-top: 1% 0;
}
.scanSetDpDnDiv {
  background: #ffffff;
  border: 1px solid #969696 !important;
  box-sizing: border-box;
  border-radius: 6px !important;
}
.scanSetingInpDivs {
  margin: 5px 0 !important;
}
.settings-tab-sub-title-div {
  margin: 3% 0 3% 0;
  padding-right: 0px !important;
}
.settings-tab-sub-title,
.settings-tab-sub-title2 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;
  color: #1c1c1c;
  margin: 0px;
  border-bottom: 1px solid #cccccc;
}
.settings-tab-sub-title {
  width: 90% !important;
}
.settings-tab-sub-title2 {
  width: 100% !important;
}

.bottomBorderRow {
  border-bottom: 1px solid #cccccc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.fw-bold {
  font-weight: bold;
}

.disbaleSaveBtn {
  opacity: 0.5;
  pointer-events: none;
}

.display-hidden {
  display: hidden !important;
}
.settingsLocalScanRow {
  margin-top: 3%;
  margin-bottom: 4%;
}

.msgMgmtTabsDiv {
  max-width: 96% !important;
  width: 100%;
}
.msgMgmtBtnDiv {
  max-width: 4% !important;
}

.page-header--col {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 15px;
}

.ruleCriteriaBtn {
  background-color: #969696;
  opacity: 1;
  padding: 10px !important;
  border-radius: 5px !important;
  font-weight: bold !important ;
  text-transform: capitalize !important;
  font-size: 14px !important;
}
.addQnsBtn {
  background: #fff !important;
  border-radius: 4px !important;
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #636799 !important;
  word-break: break-all !important;
  padding: 1.4rem !important;
  border: 1px solid #636799 !important;
}

.selectedtem {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  font-size: 12px !important;
  text-decoration: underline !important;
  font-weight: 600 !important;
  cursor: pointer;
  // border-bottom: 1px solid #000;
}
.selectedRow {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #737ae6;
  border-radius: 4px;
}

.casualData-table {
  table-layout: fixed;
  width: 100%;
}

.casualData-table td {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.casualDataQuestionSet-table thead {
  background: #636799 !important;
  border-radius: 4px !important;
}
.casualDataQuestionSet-table th {
  color: #fff !important;
}

.color-white {
  color: #fff !important;
}
