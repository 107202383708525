@import "../../../../styles/global.scss";

.miscSalvage-form {
  display: flex;
  justify-content: space-between;
  .miscSalvage-form-column {
    width: 50%;
    padding: 1rem;
    .miscSalvage-form-field {
      width: 100%;
      display: flex;
      margin: 1rem 0;
      @include InputStyle();
    }

    .miscSalvage-radio {
      width: 100%;
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .miscSalvage-form-radiobuttons {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;
        margin-left: 1.6rem;
        .miscSalvage-form-radiobuttons-withinput {
          flex-direction: row !important;
          .radiobuttons-input {
            @include InputStyle();
          }
        }
      }
    }
  }
}
.miscSalvageItem-form-buttons {
  display: flex;
  justify-content: end;
}

// .percOfCost{
//   position: relative;
//   right: 8rem;
// }
.percOfCost {
  position: relative;
  right: 1rem;
  top: -3rem;
  font-weight: bold;
}
p.miscSalvage-cal-price {
  font-weight: bold;
  margin-left: 6.7rem;
}
.miscSalvage-cal-price {
  margin-left: 1.5rem;
}
.cal-price-text {
  margin-left: 2rem;
  font-weight: 600;
}
.miscSalvage-form
  .miscSalvage-form-column
  .miscSalvage-radio
  .miscSalvage-form-radiobuttons
  .miscSalvage-form-radiobuttons-withinput
  .radiobuttons-input.misCustomInput
  span:first-of-type {
  width: 30% !important;
  margin-left: 1.2rem !important;
}
#async-upc {
  width: 95%;
}
.e-input-group .e-clear-icon, .e-input-group.e-control-wrapper .e-clear-icon{
  max-width: 22px !important;
  min-width: 20px !important;
 height: 18px !important;
 margin-top: 0.8rem !important;
 margin-right: 0.5rem !important;
 padding-right: 1rem !important;
}