.fiscal-grid .e-grid .e-gridpager {
  // background-color: #f2f2f2;
}
.e-grid .e-gridheader {
  //border-radius: 1rem 1rem 0 0;
  overflow: hidden;
  padding-right: 0;
}
.fiscal-grid.e-grid .e-rowcell {
  height: 44px;
  cursor: pointer;
  text-transform: capitalize;
  text-align: center;
}
.e-grid * {
  font-size: 16px;
}
.e-gridcontent {
  .e-content {
    overflow-y: auto !important;
    &::-webkit-scrollbar {
      height: 20px !important;
      width: 10px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      height: 5px !important;
      background: #d3d3d345 !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      height: 5px !important;
      background: #8080806e !important;
      border-radius: 10px !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #636799 !important;
    }
  }
}
.btnDraftClass {
  text-align: center;
  padding: 0.7rem 5.1rem 0.7rem 5.1rem;
  //margin-left: 3.5rem;
  border-radius: 4px;
  //styleName: Web/Body/Default;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #f2ca52;
}
.btnReadySlvgClass {
  text-align: center;
  padding: 0.7rem 2.9rem 0.7rem 2.9rem;
  // margin-left: 3.5rem;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
}
.btnReadyExtctClass {
  text-align: center;
  padding: 0.7rem 2.1rem 0.7rem 2.1rem;
  // margin-left: 3.5rem;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
}
.btnFinalCloseClass {
  text-align: center;
  padding: 0.7rem 3rem 0.7rem 3rem;
  // margin-left: 3.5rem;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #bd958d;
}
.btnCancelledClass {
  text-align: center;
  padding: 0.7rem 3.3rem 0.7rem 3.3rem;
  //margin-left: 3.5rem;
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
}
.btnPickedClass {
  text-align: center;
  padding: 0.7rem 4.5rem 0.7rem 4.5rem;
  // margin-left: 3.5rem;
  border-radius: 4px;
  //styleName: Web/Body/Default;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8876f3;
}
.btnPickupClass {
  text-align: center;
  padding: 0.7rem 2.5rem 0.7rem 2.5rem;
  // margin-left: 3.5rem;
  border-radius: 4px;
  //styleName: Web/Body/Default;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8876f3;
}
.btnPickupHoldClass {
  text-align: center;
  padding: 0.7rem 2rem 0.7rem 2rem;
 // margin-left: 3.5rem;
  border-radius: 4px;
  //styleName: Web/Body/Default;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8876f3;
}
.btnActiveClass {
  text-align: center;
  padding: 0.7rem 1.6rem 0.7rem 1.4rem;
  //margin-left: 2rem;
  border-radius: 4px;
  //styleName: Web/Body/Default;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
}
// .btnActiveClass:hover {
//   text-align: center;
//   background: #c9e7b9;
//   background-clip: content-box;
// }
.btnInActiveClass {
  text-align: center;
  padding: 0.7rem;
  //margin-left: 2rem;
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f;
  //styleName: Web/Body/Default;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
}

.btnApprovedClass {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    #79c24f;
  border-radius: 4px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #ffffff !important;
  opacity: 0.8;
  display: list-item;
  margin: 0 10%;
  padding: 5px 10px;
}

.btnDeniedClass {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #8c4d3f;
  border-radius: 4px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px;
  text-align: center;
  color: #ffffff !important;
  letter-spacing: 0.0025em;
  opacity: 0.8;
  display: list-item;
  margin: 0 10%;
  padding: 5px 10px;
}

.e-rowcell {
  text-align: center;
}

.btnYesClass {
  text-align: center;
  padding-right: 2.6rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 2.6rem;
  //padding: 0.7rem 1.6rem 0.7rem 1.4rem ;
  margin-left: 3.5rem;
  border-radius: 4px;
  //styleName: Web/Body/Default;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
}
.btnNoClass {
  text-align: center;
  padding-right: 3rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 3rem;
  margin-left: 3.5rem;
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f;
  //styleName: Web/Body/Default;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background-color: #737ae6;
}
.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: #737ae6;
}
.e-grid.e-default .e-gridheader th.e-firstcell,
.e-grid.e-default .e-gridheader th.e-lastcell {
  border-left-width: 0 !important;
}

/* Grid footer */
/* Pagination */

.e-grid .e-gridpager {
  // background-color: #f2f2f2 !important;
}

// .e-pager .e-pagercontainer {
//     background-color: #fff;
//     border-color: #e0e0e0;
//     border-radius: 4px;
//     border-style: solid;
//     border-width: 0;
//     display: inline-block;
//     margin: 5px 6px 5px 18px;
//     overflow: hidden;
//     // display: none;
// }

// .e-pager .e-pagerconstant {
//     visibility: hidden;
//     position: relative;
// }

.e-pager .e-pagerconstant::after {
  visibility: visible;
  content: "Rows per page";
  //margin: 1.5% !important;
  margin-top: 1.5rem;
  overflow: hidden;
  width: auto;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #1c1c1c;
  float: left !important;
  display: flex;
  justify-content: left !important;
}

// .e-pager .e-pagerdropdown {
//     height: 32px;
//     overflow: hidden;
//     vertical-align: baseline !important;
//     width: 90px;
//     float: left;
//     margin: 1% !important;
// }

// .e-pager .e-pagesizes {
//     justify-content: left !important;
// }

.e-numericcontainer {
  display: none !important;
}
.e-constant {
  display: none !important;
}
.e-first.e-icons.e-icon-first.e-firstpagedisabled.e-disable {
  display: none !important;
}

.e-first.e-icons.e-icon-first.e-firstpage.e-pager-default {
  display: none !important;
}

.e-last.e-icons.e-icon-last.e-lastpage.e-pager-default {
  display: none !important;
}

a.e-link.e-np.e-spacing.e-numericitem.e-pager-default {
  display: none !important;
}

a.e-link.e-pp.e-spacing.e-numericitem.e-pager-default {
  display: none !important;
}
.e-pagercontainer {
  float: right;
}
.e-pagerconstant {
  float: left;
  margin-top: 0.6rem !important;
}
.e-pager .e-pagerdropdown {
  margin-top: 1.29rem;
  background: #ffffff !important;
  border: 1px solid #969696 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.02em;
  color: #1c1c1c;
}
.e-gridpager.e-control.e-pager.e-lib {
  background: #eae7e7;
}

.e-pagercontainer {
  background: #f4f4f4 !important;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
  background: #edeaea;
}
.e-prev.e-icons.e-icon-prev.e-prevpage.e-pager-default {
  background: transparent;
}
.e-last.e-icons.e-icon-last.e-lastpagedisabled.e-disable {
  display: none;
}
.e-grid .e-rowcell:last-child,
.e-grid .e-summarycell:last-child {
  padding: 0px !important;
}

.btnActiveNoClassPallet {
  padding: 0.7rem 1.75rem 0.7rem 1.75rem;
  //margin-left: 3.5rem;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #8c4d3f;
}
.e-grid
  .e-gridheader
  .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow) {
  color: #ffffff;
}
.e-grid th.e-headercell[aria-sort="ascending"] .e-headertext,
.e-grid th.e-headercell[aria-sort="descending"] .e-headertext,
.e-grid th.e-headercell[aria-sort="ascending"] .e-sortfilterdiv,
.e-grid th.e-headercell[aria-sort="descending"] .e-sortfilterdiv {
  color: #ffffff;
}

// .e-headercelldiv.e-headerchkcelldiv.e-checkbox-wrapper .e-frame,
// .e-css.e-checkbox-wrapper .e-frame {
//   border: 2px solid #ffffff !important;
//   box-sizing: border-box;
//   border-radius: 1px !important;
//   background: transparent !important;
// }

.btnApprovedCMClass {
  text-align: center;
  padding: 0.7rem 3.4rem 0.7rem 3.4rem;
  // margin-left: 3.5rem;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: #e3e4fa;
}
.btnRejectedCMClass {
  text-align: center;
  padding: 0.7rem 3.7rem 0.7rem 3.7rem;
  // margin-left: 3.5rem;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: #c1c2d6;
}
.btnInProcessCMClass {
  padding: 0.7rem 3.1rem 0.7rem 3.1rem;
 // margin-left: 3.5rem;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.0025em;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    #79c24f;
}
