.invoices-container {
  //padding: 1rem 4rem;
  padding: 1rem 4rem 0rem 7.3rem;
  .invoices-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-left: 0.5rem;
  }
  .invoices-content {
    margin-left: 0.5rem;
  }
}

.invoices-controls-container {
  padding: 5px 0;
}
.statusShow {
  background-color: green;
}
.btnCancelInvoice {
  margin-right: 1rem !important;
  text-align: center;
  padding: 1rem;
  border: 1px solid #636799 !important;
  color: #636799 !important;
}
.iconCopy :hover {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.iconCopy {
  padding: 0px !important;
  box-shadow: none !important;
  border: none;
  margin-right: 0.5rem !important;
}
.btnDeleteIcon {
  margin-left: 2rem;
  margin-right: 1rem;
}
.btnDeleteIcon:disabled {
  cursor: not-allowed !important;
}
.iconCopy:disabled {
  cursor: not-allowed !important;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #747be6 !important;
}
.e-tab .e-tab-header .e-indicator {
  //background: #747be6 !important;
  margin-right: 1.7rem;
  background: #e0e3e7 !important;
}
.e-tab-wrap {
  margin-right: 2rem;
  // border-bottom: 1px solid red !important;
  padding: 0px 0rem 0px 0px !important;
}
.invoice-table-header span {
  border-right: 1px solid black;
}
.invoice-table-header span:last-child {
  border-right: none;
}

.hide-show-text {
  font-weight: 300 !important;
}
.miscSalvage-form {
  margin-right: 5rem;
}
.statusbutton {
  display: flex;
}
.side-btn {
  //background: #f2ca52;
  background-image: linear-gradient(#54a0eb, #51f0b8);
  border: #f2ca52;
  border-radius: 3px;
  margin-left: 1rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
}
.side-btn:after {
  content: ">";
}
.attachment-pallet-grid {
  margin-top: 3rem;
}
#defaultLayout {
  height: auto !important;
}
