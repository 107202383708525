.invoice-table {
  width: 80%;
  max-width: 750px;
  margin: 1rem;
  margin: 0 auto;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid #dedede;
  overflow: hidden;
  .invoice-table-header {
    background-color: #e0e1eb;
    display: flex;
    padding: 8px;
    justify-content: space-between;
    width: 100%;
    & > span {
      width: 16%;
    }
  }
  .invoice-table-content {
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    & > span {
      width: 16%;
    }
  }
}
