.child-tab-title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  padding: 0 0 5px;
  margin: 0px;
}

.child-data-section {
  margin-top: 1.5%;
}

.preview-pane {
  height: calc(100vh - 265px);
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #63679954;
}

.childTab-preview-pane {
  height: calc(100vh - 298px);
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #b4b5c8;
}

.preview-msg {
  margin: 0;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #1c1c1c !important;
}
//rajaravanam formcontrol
.formcontrol {
  width: 100%;
  height: 34px;
  outline: none;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #1c1c1c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #969696;
  border-radius: 6px;
  box-sizing: border-box !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  box-shadow: inset 0 1px 1px (0 0 0 / 8%);
}
.formDesc {
  width: 100%;
  height: auto;
  outline: none;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #1c1c1c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #969696;
  border-radius: 6px;
  box-sizing: border-box !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  box-shadow: inset 0 1px 1px (0 0 0 / 8%);
}
