.label-print {
    font-family: 'Rubik';
    font-size: 16px;

    .type-title {
        font-weight: bold;
        text-align: left;
    }

    .scan-mode-types {
        text-align: left;
        margin: 10px 0 20px 0;
        display: flex;
    }

    .type-group {
        margin: 0 24px 0 0;
        display: flex;
    }

    .radio-button-title {
        height: 20px;
        margin-left: 8px;
    }

    .type-radio-button {
        height: 20px;
        width: 20px;
        border: 1.25px solid #1c1c1c;
        margin: 0;
    }

    .label-search-container {
        padding: 0;
        width: 262px;
        margin-right: 32px;

        .form-control.label-id-search {
            height: 50px;
        }

        .label-search-results {
            .e-listbox-wrapper {
                text-align: left;
            }

            .e-list-parent .e-list-item {
                height: 56px;
                padding: 20px 16px;
            }

            .e-list-parent .e-list-item.e-selected {
                background: linear-gradient(
                        0deg,
                        rgba(255, 255, 255, 0.8),
                        rgba(255, 255, 255, 0.8)
                    ),
                    #737ae6;
                border-radius: 4px 4px 0px 0px;
            }
        }
    }

    .label-details-container {
        background: #f2f2f2;
        border-radius: 4px;
        display: flex;
        width: 640px;
        padding: 30px;

        .label-details-view {
            width: 580px;

            .label-row {
                display: flex;
                margin: 16px;

                .label-name {
                    width: 150px;
                }

                .label-value {
                    text-align: left;
                }
            }
        }
    }

    .keyboardContainer {
        width: auto;
        min-width: unset;
    }

    .reprint-label {
        height: 100px;
        width: 100px;
    }
}
