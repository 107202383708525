@import "../../../styles/global.scss";

.input-container {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  .input-control {
    display: flex;
    width: 30% !important;
    @include InputStyle;
  }
  .radio-group-invoice {
    display: flex;
    width: 100%;
    justify-content: end;
    font-weight: 700;
    padding: 10px 0;
    margin: 10px 0;
    & > span {
      margin-right: 3rem;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 18px;
    }
  }
}
.e-accordion {
  background-color: white;
  border: none;
}
.e-accordion .e-acrdn-item.e-select > .e-acrdn-header {
  background: white !important;
  justify-content: center;
  border: none !important;
  outline: none !important;
  &:focus {
    border: none !important;
    outline: none !important;
  }
  &:active {
    border: none !important;
    outline: none !important;
  }
}

.custom-accordion {
  overflow: hidden;
  .child-container-collapsed {
    height: 0;
    transition: all 1s ease-in;
  }
  .child-container-expended {
    height: 200px;
  }
  .custom-accordion-button {
    cursor: pointer;
    display: flex;
    padding: 1rem;
  }
}

.invoice-attachment {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 90%;
  width: 65% !important;
  border: 1px solid gray !important;
  border-radius: 4px !important;
}
.invoice-attachment-bol {
  height: 100% !important;
  width: 100% !important;
  border-radius: 6px !important;
}
.attachment-modal-content {
  display: flex;
  width: 65vw;
  height: 65vh;
  .attachment-modal-filedrop {
    width: 55%;
    .attachment-modal-filesection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dashed lightgray;
      height: 60%;
      margin-bottom: 30px;
      & > input {
        display: none;
      }
    }
  }
  .attachment-modal-notes {
    width: 45%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }
}

.width-65 {
  width: 65%;
}
.bolHead {
  margin-top: 0.6rem;
  text-align: left;
}
.bolIDForUpdate {
  font-size: 1.5rem;
}
.inputTextBol {
  border: 1px solid !important;
  border-radius: 6px !important;
  border-bottom: none !important;
  padding-left: 1rem !important;
}
.hrBOL {
  margin-bottom: 0px;
  margin-top: 0.5rem;
}
.invoiceNumView {
  font-size: 1.4rem;
  font-weight: 100;
  margin-top: 5px;
}
// .radio-group-invoice.bol-radio {
//   margin-left: 12rem;
// }
// .freight-radio{
//   margin-left: -8rem
// }
// .radio-group-invoice.bol-radio-btns {
//   display: block !important;
// }
// .radio-group-invoice.bol-radio-btns .e-radio-wrapper {
//   margin-top: 0.3rem;
// }
.billingTypeRadio {
  margin-left: 5.2rem;
}
.pickupmargin {
  margin-top: 0.6rem;
}
.txtTrailer {
  height: 70% !important;
}
.e-switch-wrapper.e-wrapper:active {
  width: 17%;
}
.e-switch-wrapper.e-wrapper {
  width: 20%;
}
.txtNoteMargin {
  margin-left: 5rem;
}
.freezRadios {
  padding-left: 5rem;
}
.txtInvoiceNumCM {
  height: 31px;
  border-radius: 4px !important;
  width: 109%;
  color: #898989 !important;
  text-align: center;
}
select.ll-custom-dropdown.form-control {
  width: 100%;
}
.cus-field {
  padding-right: 0.9rem !important;
}
.e-spinner-inner {
  display: none;
}
.do-not-freeze {
  margin-left: -4.3rem;
}
