.invoices-home {
  padding: 3% 5.1%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //   color: #
  .invoice-images {
    display: flex;
    padding-top: 1rem;
  }
  .invoice-images .image-wrapper {
    margin-right: 35px !important;
    width: 235px !important;
  }
}
