.countryFlagImage {
  width: 30px;
  height: 20px;
  object-fit: cover;
  margin-right: 0px;
}
.dropdown-content {
  right: 0;
}

.Countrydropdown {
  width: 20px;
  border: none;
  background-color: fff;
  margin-left: 0px;
}
.Countrydropdown:focus {
  outline: none !important;
}
.countrycodetext {
  margin-left: 0px !important;
}
.countrytext {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 13px;
  font-weight: bold;
}

.MobileInputNumber {
  background-color: fff;
  margin-left: 0px;
  border: none !important;
  width: 58% !important;
}
.MobileInputNumber:focus {
  outline: none !important;
}
.mobilefieldmain {
  justify-content: center;
  align-items: center;
  line-height: 20px;
  position: relative;
  border: 1px solid #969696 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-family: Rubik !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
}
select:focus {
  outline: none;
}

span.ActiveInactiveFont {
  font-size: 15px;
}
