body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.alt-address-style {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e4e3e3;
    padding: 0 15px;
    margin: 10px 0;
    font-weight: 600;
    cursor: pointer;
}
